import React from 'react'
import { useSelector } from 'react-redux'

export default function SavedAccountsList({ chooseAccount, setIsAccountEdit }) {
    const savedAccounts = useSelector((state) => state.juno.savedAccounts)
    function handleIsEditAccount(e, id) {
        e.stopPropagation()
        setIsAccountEdit(id)
    }
    return (
        <div>
            {savedAccounts.map((x) => (
                <div
                    onClick={() => chooseAccount(x._id)}
                    key={x._id}
                    className="savedAccounts-item"
                >
                    <svg
                        className="savedAccounts-item-icon"
                        width="44"
                        height="44"
                        viewBox="0 0 44 44"
                        fill="none"
                    >
                        <rect width="44" height="44" rx="22" fill="#F2F4F7" />
                        <path
                            d="M17 21H15V28H17V21ZM23 21H21V28H23V21ZM31.5 30H12.5V32H31.5V30ZM29 21H27V28H29V21ZM22 14.26L27.21 17H16.79L22 14.26ZM22 12L12.5 17V19H31.5V17L22 12Z"
                            fill="#344054"
                        />
                    </svg>
                    <p>
                        {x.accountNickName} <br />
                        <span>{x.sortCode}</span>
                    </p>
                    <button
                        type="button"
                        onClick={(e) => handleIsEditAccount(e, x._id)}
                        className="savedAccounts-edit"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                        >
                            <path
                                d="M18.8693 12.4751L15.5353 9.14114M5.9502 22.0602L8.77102 21.7468C9.11566 21.7085 9.28798 21.6893 9.44904 21.6372C9.59194 21.5909 9.72793 21.5256 9.85332 21.4429C9.99465 21.3497 10.1172 21.2271 10.3624 20.9819L21.3697 9.97463C22.2904 9.05399 22.2904 7.56132 21.3697 6.64068C20.4491 5.72003 18.9564 5.72003 18.0358 6.64068L7.0285 17.6479C6.7833 17.8931 6.66071 18.0157 6.56751 18.1571C6.48482 18.2825 6.41946 18.4184 6.3732 18.5613C6.32106 18.7224 6.30191 18.8947 6.26362 19.2394L5.9502 22.0602Z"
                                stroke="#667085"
                                strokeWidth="1.6"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                </div>
            ))}
        </div>
    )
}
