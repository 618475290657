import React from 'react'
import { useSelector } from 'react-redux'

export default function JunoAmFooter() {
    const image_base_url = useSelector((state) => state.config.image_base_url)

    return (
        <footer className="jnAm-footer">
            <div className="content-container">
                <div className="jnAm-footer-top">
                    <img
                        className="jnAm-header-logo"
                        src={`${image_base_url}/juno-am/logo.webp`}
                        alt=""
                    />
                    <div className="jnAm-footer-socials">
                        <a href="#" target="_blank">
                            <img
                                width="24"
                                height="24"
                                src={`${image_base_url}/juno-am/linkedin.webp`}
                                alt=""
                            />
                        </a>
                        <a href="#" target="_blank">
                            <img
                                width="24"
                                height="24"
                                src={`${image_base_url}/juno-am/instagram.webp`}
                                alt=""
                            />
                        </a>
                        <a href="#" target="_blank">
                            <img
                                width="24"
                                height="24"
                                src={`${image_base_url}/juno-am/twitter.webp`}
                                alt=""
                            />
                        </a>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="jnAm-footer-block">
                    <p className="jnAm-footer-text jnAm-footer-copyright">
                        <span>© Juno 2023 | All rights reserved</span>
                    </p>
                    <div className="jnAm-footer-links">
                        <a href="#" className="jnAm-footer-text">
                            Terms of Use
                        </a>
                        <div className="line"></div>
                        <a href="#" className="jnAm-footer-text">
                            Privacy Policy
                        </a>
                        <div className="line"></div>
                        <a href="#" className="jnAm-footer-text">
                            Cookie Policy
                        </a>
                    </div>
                </div>
                <p className="jnAm-footer-text">
                    Juno Management Solutions AG (registered number:
                    CHE-238.309.010). It is an SRO member of VQF, an officially
                    recognised self-regulatory organisation (SRO) under the
                    supervision of FINMA, according to the Swiss Anti-Money
                    Laundering Act.
                </p>
            </div>
        </footer>
    )
}
