import React from 'react'
import { useSelector } from 'react-redux'
import { getCurrencySymbol, toFixedTrunc } from '../../../../../../helpers'

const SellConfirm = ({ formData, exchangeRate }) => {
    const { balancesCurrencies, balancesCrypto } = useSelector(
        (state) => state.juno
    )
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)
    const fiatIcon = balancesCurrencies.find(
        (x) => x.currencyShortName === formData.fiatCurrency,
    ).currencyIcon
    return (
        <>
            <p className="text-base font-normal text-[#51525C] ">
                Please, doublecheck the summary below, before making a purchase.
            </p>
            <div className="border rounded-lg p-3 mt-12">
                <h1 className="text-base font-semibold">
                    Transaction information:
                </h1>
                <div className="mt-5">
                    <p className="text-sm font-normal text-[#51525C] ">Sell:</p>
                    <div className="flex justify-between items-center">
                        <div className="flex items-center gap-2">
                            <img src="" alt="img" className="" width={24} height={24} />
                            <span className="text-xl font-normal text-[#26272B]">
                                {formData.cryptoId}
                            </span>
                        </div>
                        <p className="text-xl font-semibold text-[#26272B] ">
                            $ {formData.cryptoAmount} ({formData.cryptoId})
                        </p>
                    </div>
                </div>
                <hr className="my-3" />
                <div className="">
                    <p className="text-sm font-normal text-[#51525C] ">
                        Receive:
                    </p>
                    <div className="flex justify-between items-center">
                        <div className="flex items-center gap-2">
                            <img src={`${imageBaseUrl}${fiatIcon}`} width={24} height={24} alt="img" className="" />
                            <span className="text-xl font-normal text-[#26272B]">
                                {formData.fiatCurrency}
                            </span>
                        </div>
                        <p className="text-xl font-semibold text-[#26272B]">
                            $ {formData.fiatAmount} ({formData.fiatCurrency})
                        </p>
                    </div>
                </div>
                <hr className="my-3" />
                <div className="flex justify-between items-center">
                    <p className="text-base font-normal text-[#51525C] ">
                        Rate:
                    </p>
                    <p className="text-base font-medium text-[#18181B] ">
                        1 {formData.cryptoId} = {toFixedTrunc(1 / exchangeRate, 2)} {formData.fiatCurrency}
                    </p>
                </div>
                <div className="mt-3 flex justify-between items-center">
                    <p className="text-base font-normal text-[#51525C] ">
                        Fee:
                    </p>
                    <p className="text-base font-medium text-[#18181B]">
                        0.2%
                    </p>
                </div>
                <hr className="my-3" />
                <div className="flex justify-between items-center">
                    <p className="text-base font-normal text-[#51525C] ">
                        Total:
                    </p>
                    <p className="text-base font-medium text-[#18181B]">
                        {getCurrencySymbol(formData.fiatCurrency)} {formData.fiatAmount || 0} ({formData.fiatCurrency})
                    </p>
                </div>
            </div>
        </>
    )
}

export default SellConfirm
