import * as actionTypes from '../actions/actionTypes';

const initialState = {
    searchValue: '',
    isIndividualState: true,
    clientData: null,
    balancesCurrencies: [],
    balancesCrypto: [],
    transactionsCurrencies: [],
    transactionsCrypto: [],
    clientTransactionFee: [],
    totalFiatAmount: 0,
    totalCryptoAmount: 0,
    savedAccounts: [],
    statements: [],
    activeCurrencyPage: "",
    snackbar: { open: false, message: '', severity: '' },
}

function setJunoTableSearchValue(state, action) {
    return {
        ...state,
        searchValue: action.value,
    }
}

function setIndividualStateInfo(state, action) {
    return {
        ...state,
        isIndividualState: action.value,
    }
}

function setClientData(state, action) {
    return {
        ...state,
        clientData: action.value,
    }
}

function setBalancesData(state, action) {
    return {
        ...state,
        balancesCurrencies: action.value.clientBalanceList,
        totalFiatAmount: action.value.totalFiatAmount,
    }
}

function setCryptoBalancesData(state, action) {
    return {
        ...state,
        balancesCrypto: action.value.clientBalanceList,
        totalCryptoAmount: action.value.totalCryptoAmount,
    }
}

function setAccountsData(state, action) {
    return {
        ...state,
        savedAccounts: action.value,
    }
}

function setTransactionsData(state, action) {
    return {
        ...state,
        transactionsCurrencies: action.value.filter(
            (x) => x.currencyType === 'fiat',
        ),
        transactionsCrypto: action.value.filter(
            (x) => x.currencyType === 'crypto',
        ),
        allTransactionsFiatCurrencies: state.transactionsCrypto,
    }
}

function setStatementsData(state, action) {
    return {
        ...state,
        statements: action.value,
    }
}

function setClientTransferFee(state, action) {
    return {
        ...state,
        clientTransactionFee: action.value,
    }
}

function setJunoSnackbarState(state, action) {
    return {
        ...state,
        snackbar: {
            open: true,
            message: action.payload.message,
            severity: action.payload.severity,
        },
    }
}

function closeSnackbar(state, action) {
    return {
        ...state,
        snackbar: {
            open: false,
        },
    }
}

function setActiveCurrencyPage(state, action) {
    const { value } = action;
    if (value != null) {
        return { ...state, activeCurrencyPage: value };
    }
    return state;
}

function junoReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_JUNO_TABLE_SEARCH_VALUE:
            return setJunoTableSearchValue(state, action)
        case actionTypes.SET_INDIVIDUAL_STATE_INFO:
            return setIndividualStateInfo(state, action)
        case actionTypes.SET_CLIENT_DATA:
            return setClientData(state, action)
        case actionTypes.SET_BALANCES_DATA:
            return setBalancesData(state, action)
        case actionTypes.SET_TRANSACTIONS_DATA:
            return setTransactionsData(state, action)
        case actionTypes.SET_CLIENT_FEE_SETTING:
            return setClientTransferFee(state, action)
        case actionTypes.SET_SUCCESS_MESSAGE:
        case actionTypes.SET_ERROR_MESSAGE:
            return setJunoSnackbarState(state, action)
        case actionTypes.CLOSE_SNACKBAR:
            return closeSnackbar(state, action)
        case actionTypes.SET_ACCOUNTS_DATA:
            return setAccountsData(state, action)
        case actionTypes.SET_STATEMENTS_DATA:
            return setStatementsData(state, action)
        case actionTypes.SET_CURRENCY_PAGE:
            return setActiveCurrencyPage(state, action)
        case actionTypes.SET_CRYPTO_BALANCES_DATA:
            return setCryptoBalancesData(state, action)
        default:
            return state
    }
}

export default junoReducer
