import React, { useEffect, useState } from 'react'
import JunoClientActionModal from '../../components/table/JunoClientActionModal'
import JncButton from '../../components/global/JncButton'
import { JncCustomSelect } from '../../components/global/JncCustomSelect'
import { useSelector } from 'react-redux'
import { apiService } from '../../../../common/apiCallService'
import CopyButton from '../../components/global/CopyButton'
import { JncConfirm } from '../../components/global/JncConfirm'

export const JncCryptoAddresses = (props) => {
    const { onClose, open, title, getCryptoAddress, addressData, addressActions } = props;
    const { balancesCrypto } = useSelector((state) => state.juno)
    const apiUrl = useSelector((state) => state.config.api_url)
    const clientData = useSelector((state) => state.juno.clientData)

    const [formData, setformData] = useState({
        cryptoId: 'BTC',
        nickName: '',
        address: '',
    })
    const [isConfirm, setIsConfirm] = useState(false)

    useEffect(() => {
        if (addressData && addressActions?.edit) {
            setformData({
                cryptoId: addressData?.cryptoId,
                nickName: addressData?.nickName,
                address: addressData?.address
            })
        }
    }, [addressData, addressActions])


    const handleAddress = (endpoint, payload) => {
        apiService(
            (window.location.hostname === 'localhost' ? apiUrl : '') + endpoint,
            payload,
            async (data) => {
                if (data) {
                    onClose();
                    setformData({
                        cryptoId: 'BTC',
                        nickName: null,
                        address: '',
                    });
                    getCryptoAddress();
                }
            },
            (err) => {
                console.log(err);
            }
        );
    };

    const saveNewAddress = () => {
        handleAddress('/restapi/save-crypto-address', {
            clientId: clientData.clientId,
            cryptoId: formData.cryptoId,
            address: formData.address,
            nickName: formData.nickName,
        });
    };

    const updateAddress = () => {
        handleAddress('/restapi/update-crypto-address', {
            clientId: clientData.clientId,
            cryptoId: formData.cryptoId,
            address: formData.address,
            nickName: formData.nickName,
            addressId: addressData._id,
        });
    };


    const dialogContentComponent = () => {
        return (
            <>
                <div className="grid items-center grid-cols-3 max-md:grid-cols-subgrid gap-3 md:gap-8">
                    <h1 className="text-base font-normal text-[#26272B] col-span-1">
                        Select crypto:
                    </h1>
                    <JncCustomSelect
                        value={formData.cryptoId}
                        onChange={(e) =>
                            setformData({ ...formData, cryptoId: e.target.value })
                        }
                        options={balancesCrypto}
                        className="!w-full col-span-2 !rounded-l-3xl"
                        inputProps={{
                            className: '!py-[15px]',
                        }}
                    />
                </div>
                <div className="grid items-center grid-cols-3 max-md:grid-cols-subgrid gap-3 md:gap-8 mt-8">
                    <h1 className="text-base font-normal text-[#26272B] col-span-1">
                        Wallet Address:
                    </h1>
                    <div className="jn-addressdetails-content-input col-span-2 bg-input border-[#D1D1D6] rounded-3xl py-[17px] pl-6 overflow-hidden">
                        <input
                            type="text"
                            placeholder='Enter wallet address'
                            onChange={(e) =>
                                setformData({
                                    ...formData,
                                    address: e.target.value,
                                })
                            }
                            value={formData.address}
                            className="bg-input w-10/12"
                        />
                        {addressActions?.edit &&
                            <CopyButton
                                className="jn-addressdetails-content-copy"
                                copyValue={formData.address}
                            />}
                    </div>
                </div>
                <div className="grid items-center grid-cols-3 max-md:grid-cols-subgrid gap-3 md:gap-8 mt-8">
                    <h1 className="text-base font-normal text-[#26272B] col-span-1">
                        Nickname:
                    </h1>
                    <input
                        type="text"
                        placeholder='Create address nickname'
                        onChange={(e) =>
                            setformData({
                                ...formData,
                                nickName: e.target.value,
                            })
                        }
                        value={formData.nickName}
                        className="bg-input col-span-2 rounded-3xl w-full py-[17px] pl-6"
                    />
                </div>

            </>
        )
    }

    const dialogActionsComponent = () => {
        return (
            <div className="w-100">
                {addressActions?.edit &&
                    <div className="jncModal__actions addressTwoBtns-right justify-end ">
                        <JncButton
                            isFullOnMobile={true}
                            text="Save address"
                            onClickCall={() => {
                                onClose();
                                setIsConfirm(true)
                            }}
                        />
                    </div>
                }

                {!(addressActions?.edit) &&
                    <div className="jncModal__actions addressTwoBtns-right justify-end ">
                        <JncButton
                            isFullOnMobile={true}
                            text="Create new"
                            onClickCall={saveNewAddress}
                        />
                    </div>}

            </div>
        )
    }

    return (
        <>
            <JunoClientActionModal
                onClose={onClose}
                titles={{ start: title, className: "jn-addressdetails-contentweight" }}
                dialogClassName={"jn-addressdetails-view"}
                contentClassName="jn-addressdetails-contentviewbox"
                states={{
                    start: true,
                    confirm: false,
                }}
                open={open}
                dialogContent={dialogContentComponent()}
                dialogActions={dialogActionsComponent()}
            />
            <JncConfirm
                open={isConfirm}
                data={formData.address}
                onClose={() => setIsConfirm(false)}
                title="Are you sure?"
                content="Are you sure you want to change the wallet address?"
                dialogClassName={"jn-addressdetails-confirm"}
                actionClassName={"jn-addressdetails-actionbox"}
                handleConfirm={updateAddress}
            />
        </>
    )
}
