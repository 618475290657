import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../Redux/actions';
import { apiService } from '../../../../common/apiCallService';
import JncAuthClose from './auth/JncAuthClose';
import JncAuthTitle from './auth/JncAuthTitle';
import JncAuthBottom from './auth/JncAuthBottom';
import { Link, useNavigate, useParams } from 'react-router-dom';
import JunoFaVerification from '../2fa/JunoFaVerification';
import JunoFaAuthenticator from '../2fa/JunoFaAuthenticator';
import { useJunoCommonFunctions } from '../../../helpers';
import JunoForgotPassword from './JunoForgotPassword';

const Login = (props) => {
  const { show, closeModal } = props
  const [clientEmail, setClientEmail] = useState('');
  const { getWhitelistData } = useJunoCommonFunctions()
  const [password, setPassword] = useState('');
  const [alertMsg, setAlertMsg] = useState(null);
  const [passwordChangedSuccessful, setPasswordChangedSuccessful] = useState(false);
  const [show2FAVerificationModal, setShow2FAVerificationModal] = useState(false)
  const [showAuthenticatorModal, setShowAuthenticatorModal] = useState(false)
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false)
  const [merchantData, setMerchantData] = useState(null)
  const [merchantEmail, setMerchantEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [openModal, setopenModal] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate()
  const headerLogo = sessionStorage.getItem("logo")
  const primaryColor = sessionStorage.getItem("primaryColor")
  const logoWidth = sessionStorage.getItem("logoWidth")
  const imageBaseURl = useSelector((state) => state.config.image_base_url)


  useEffect(() => {
    if (show) {
      setopenModal(show)
    }
  }, [show])


  const fetchData = async () => {
    if (id) {
      const whiteLabelData = await getWhitelistData();
      if (whiteLabelData) {
        const whitelabelId = id;
        const whiteLabel = whiteLabelData?.whitelabelList.find(e => e.whitelabelId === whitelabelId);
        if (whiteLabel) {
          const themeColor = whiteLabel.primaryColor || '#18181b';
          sessionStorage.setItem('primaryColor', themeColor);
          sessionStorage.setItem('logo', whiteLabel.logo || '');
          sessionStorage.setItem("whitelabelId", whiteLabel.whitelabelId);
          sessionStorage.setItem('whitelableName', whiteLabel.whitelableName || '');
          sessionStorage.setItem('logoWidth', whiteLabel.logoWidth || '');
          sessionStorage.setItem('redirectURL', whiteLabel.redirectURL || '');
          document.documentElement.style.setProperty('--theme-color', themeColor);
          document.documentElement.style.setProperty('--btn-background-hover', themeColor);
        }
      }
      setopenModal(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);


  function onVerificationSucess(data, user_name) {
    localStorage.setItem('user_name', user_name)
    dispatch(actions.setToken(data.token))
    if (id) {
      setopenModal(false)
    } else {
      closeModal()
    }
    navigate('/client')
  }

  const dispatch = useDispatch();
  const apiUrl = useSelector(state => state.config.api_url);

  useEffect(() => {
    setPasswordChangedSuccessful(passwordChangedSuccessful);
  }, [passwordChangedSuccessful]);

  const setVerifyModal = (data, email) => {
    setShow2FAVerificationModal(true)
    setMerchantData(data)
    setMerchantEmail(email)
  }

  const setAuthenticatorModal = (data, email) => {
    setShowAuthenticatorModal(true)
    setMerchantData(data)
    setMerchantEmail(email)
  }

  const onLogin = async () => {
    setAlertMsg(null);
    dispatch(actions.setLoading(true));
    apiService(
      (window.location.hostname === 'localhost'
        ? apiUrl
        : '') + '/restapi/client-login',
      {
        clientEmail,
        password,
        domainNameUrl: window.location.hostname,
      },
      async (data) => {
        if (data) {
          dispatch(actions.setLoading(false));
          if (
            data.is2faActivated.enabled &&
            data.is2faActivated.secret != null &&
            data.is2faActivated.secret != 'null'
          ) {
            setVerifyModal(data, data.clientEmail)
          } else if (
            data.is2faActivated.enabled &&
            data.is2faActivated.secret == null
          ) {
            setAuthenticatorModal(data, data.clientEmail)
          } else {
            onVerificationSucess(data, data.clientEmail)
          }
        }
      },
      (err) => {
        dispatch(actions.setLoading(false));
        setAlertMsg(err);
        console.log(err);
      },
    )
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    name === 'clientEmail' ? setClientEmail(value) : setPassword(value);
  };

  useEffect(() => {
    const expireTime = Date.now() + 15 * 60 * 1000
    localStorage.setItem('expireTime', expireTime)
  }, [])
  return (
    <Dialog
      className="jncAuth"
      onClose={closeModal}
      open={openModal}
      fullScreen
    >
      <div className="jncAuth__body bg-brand-black">
        <div>
          <JncAuthClose onClose={closeModal} />
          <div className="jncAuth__container p-8 !max-w-[424px] bg-white rounded-lg">
            <div className="jncAuth__logo">
              {headerLogo && headerLogo !== 'null' ?
                <img src={headerLogo} alt="logo" width={logoWidth || logoWidth !== "null" ? logoWidth : "180"} height="auto" />
                :
                <img className='w-36' src={`${imageBaseURl}/landingpage/dark-logo.svg`} alt="logo" />
              }
            </div>
            <JncAuthTitle
              title="Log in"
              subtitle="Welcome back! Please enter your details."
            />
            {alertMsg && (
              <div className="alert alert-danger" role="alert">
                {alertMsg}
              </div>
            )}
            {passwordChangedSuccessful && (
              <div className="alert alert-success" role="success">
                Password has been changed!
              </div>
            )}
            <form>
              <div className="jncAuth__field mb">
                <p className="jncAuth__field-label">Email</p>
                <input
                  type="email"
                  data-e2e="userName"
                  value={clientEmail}
                  placeholder="Enter your email"
                  onChange={handleInputChange}
                  name="clientEmail"
                />
              </div>
              <div className="jncAuth__field">
                <p className="jncAuth__field-label">Password</p>
                <input
                  type="password"
                  data-e2e="passWord"
                  value={password}
                  placeholder="Enter your password"
                  onChange={handleInputChange}
                  name="password"
                />
              </div>
              <div className="theme-btn-block jncAuth__remember !mb-6">
                <div
                  data-e2e="forget-password"
                  className="jncAuth__link forgot-password text-[#1570EF]"
                  onClick={() => {
                    setForgotPasswordModal(true)
                  }}
                >
                  Forgot Password?
                </div>
              </div>
              <p className="jncAuth__subtitle !text-sm !text-left">
                By continuing you accept our
                <Link className='text-[#1570EF]' to="/juno-terms" rel="noopener noreferrer" data-e2e="terms-and-conditions">
                  {' '}
                  Terms and Conditions{' '}
                </Link>
                and
                <Link className='text-[#1570EF]' to="/juno-privacy-policy" rel="noopener noreferrer">
                  {' '}
                  Privacy Policy.
                </Link>
              </p>
              <div
                data-e2e="continue"
                style={{ background: primaryColor ? primaryColor : "#18181B", fontFamily: 'Open Sans !important' }}
                className={`jncAuth__Button w-full rounded-full flex justify-center h-11 items-center text-white font-semibold cursor-pointer full-width ${primaryColor ? `bg-[${primaryColor}]` : "bg-brand-black hover:bg-brand-gray"}`}
                onClick={onLogin}
              >
                Log In
              </div>
            </form>
          </div>
        </div>
        <JncAuthBottom />
        {
          show2FAVerificationModal && (
            <JunoFaVerification
              setLoading={setLoading}
              open={show2FAVerificationModal}
              onClose={() => setShow2FAVerificationModal(false)}
              onVerificationSucess={onVerificationSucess}
              merchantData={merchantData}
              clientEmail={merchantEmail}
            />
          )
        }
        {
          showAuthenticatorModal && (
            <JunoFaAuthenticator
              setLoading={setLoading}
              open={showAuthenticatorModal}
              onClose={() => setShowAuthenticatorModal(false)}
              merchantData={merchantData}
              merchantEmail={merchantEmail}
              onVerificationSucess={onVerificationSucess}
              component={'head'}
            />
          )
        }
        {
          forgotPasswordModal &&
          <JunoForgotPassword
            title={'Forgot Password?'}
            show={true}
            closeModal={closeModal}
            openLoginModal={() => setForgotPasswordModal(false)}
          />
        }
      </div >
    </Dialog >
  );
};

export default Login;
