import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import SidebarCurrencyModal from '../modals/SidebarCurrencyModal'
import BalanceFiatDeposit from '../../tables/balances/actions/fiat/BalanceFiatDeposit'
import BalanceFiatFx from '../../tables/balances/actions/fiat/BalanceFiatFx'
import BalanceFiatPaymentOut from '../../tables/balances/actions/fiat/BalanceFiatPaymentOut'
import BalanceFiatTransfer from '../../tables/balances/actions/fiat/BalanceFiatTransfer'
import BalanceFiatWithdraw from '../../tables/balances/actions/fiat/BalanceFiatWithdraw'
import {
    toFixedTrunc,
    getCurrencySymbol,
    useJunoCommonFunctions,
    MenuItems,
} from '../../../helpers'
import { Link, useLocation } from 'react-router-dom'

export default function JncSidebar({ sidebarState }) {
    const { getBalances, useWindowWidth } = useJunoCommonFunctions()
    const clientData = useSelector((state) => state.juno.clientData)
    const balancesData = useSelector((state) => state.juno.balancesCurrencies)
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)
    const [modalState, setModalState] = useState(false)
    const [modalData, setModalData] = useState('')
    const [modalAction, setModalAction] = useState('')
    const [hideOnProfile, setHideOnProfile] = useState(false)
    const { pathname } = useLocation()
    const windowWidth = useWindowWidth()


    function openModal(data) {
        setModalData(data)
        setModalState(true)
    }
    const onClose = () => setModalState(false)
    useEffect(() => {
        if (clientData) {
            getBalances()
        }
    }, [clientData])

    useEffect(() => {
        setHideOnProfile(pathname == '/client/profile')
    }, [pathname])

    return (
        <div
            className={`jncSidebar ${sidebarState ? 'opened' : ''} ${hideOnProfile ? 'hide-on-profile' : ''}`}
        >
            <div className="jncSidebar__menu">
                {MenuItems.slice(0, windowWidth <= 978 ? -1 : undefined).map((item) => (
                    <Link key={item.id} to={item.path} exact className={`jncSidebar__menu-link ${pathname === item.path ? "active" : ""}`}>
                        {item.icon}
                        <p>{item.title}</p>
                    </Link>
                ))}
            </div>
            <div className="jncSidebar__currs min-992">
                {balancesData.map((x) => (
                    <button
                        onClick={() => openModal(x)}
                        key={x.currencyFullName}
                        className="jncSidebar__currs-btn"
                    >
                        <div className="jncSidebar__currs-img">
                            <img
                                src={`${imageBaseUrl}${x.currencyIcon}`}
                                alt=""
                                data-e2e={`${x.currencyShortName}-side-tab`}
                            />
                            <p>
                                {getCurrencySymbol(x.currencyShortName)}{' '}
                                {toFixedTrunc(x.balanceAmount, 2)}
                            </p>
                        </div>
                        <p className="jncSidebar__currs-bottom" data-e2e={getCurrencySymbol(x.currencyShortName)} data-e2e-value={toFixedTrunc(x.balanceAmount, 2)}>
                            {getCurrencySymbol(x.currencyShortName)}{' '}
                            {toFixedTrunc(x.balanceAmount, 2)}
                        </p>
                    </button>
                ))}
            </div>
            <SidebarCurrencyModal
                open={modalState}
                data={modalData}
                onClose={onClose}
                setModalAction={setModalAction}
            />
            <BalanceFiatDeposit
                open={modalAction === 'deposit'}
                onClose={() => setModalAction('')}
                row={modalData}
            />
            <BalanceFiatWithdraw
                open={modalAction === 'withdraw'}
                onClose={() => setModalAction('')}
                row={modalData}
            />
            <BalanceFiatPaymentOut
                open={modalAction === 'paymentOut'}
                onClose={() => setModalAction('')}
                row={modalData}
            />
            <BalanceFiatFx
                open={modalAction === 'fx'}
                onClose={() => setModalAction('')}
                row={modalData}
            />
            <BalanceFiatTransfer
                open={modalAction === 'transfer'}
                onClose={() => setModalAction('')}
                row={modalData}
            />
        </div>
    )
}
