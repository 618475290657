import ClearIcon from '@mui/icons-material/Clear'
import Dialog from '@mui/material/Dialog'
import qrcode from 'qrcode'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { apiService } from '../../../../common/apiCallService'
import { checkValueInSS, useHeaderButtonStyle } from '../../../../common/theme'
import { useJunoCommonFunctions } from '../../../helpers'

const JunoFaAuthenticator = (props) => {
    const { getSecretKey } = useJunoCommonFunctions()
    const api_url = useSelector((state) => state.config.api_url)
    const [error, setError] = useState('')
    const [verificationCode2fa, change2faVerificationCode] = useState('')
    const [imageData, setImageData] = useState(null)
    const [secret, setSecret] = useState(null)
    const [colorMode, setColorMode] = useState(null)
    const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] =
        useState(null)
    const [headerButtonLightBgColour, setheaderButtonLightBgColour] =
        useState(null)
    const [headerDarkTextColour, setheaderDarkTextColour] = useState(null)
    const [headerLightTextColour, setheaderLightTextColour] = useState(null)

    useEffect(() => {
        const updateSettings = async () => {
            if (checkValueInSS('colorMode')) {
                setColorMode(sessionStorage.getItem('colorMode'));
            }
            if (checkValueInSS('headerButtonDarkBgColour')) {
                setheaderButtonDarkBgColour(
                    sessionStorage.getItem('headerButtonDarkBgColour')
                );
            }
            if (checkValueInSS('headerButtonLightBgColour')) {
                setheaderButtonLightBgColour(
                    sessionStorage.getItem('headerButtonLightBgColour')
                );
            }
            if (checkValueInSS('headerDarkTextColour')) {
                setheaderDarkTextColour(
                    sessionStorage.getItem('headerDarkTextColour')
                );
            }
            if (checkValueInSS('headerLightTextColour')) {
                setheaderLightTextColour(
                    sessionStorage.getItem('headerLightTextColour')
                );
            }

            const data = await getSecretKey();
            if (data) {
                setSecret(data);
            }
        };

        updateSettings();
    }, [])

    useEffect(() => {
        if (secret) {
            generateQRcode()
        }

    }, [secret])

    const generateQRcode = async () => {
        let imageData = null
        await qrcode.toDataURL(secret.otpauth_url, function (err, imgData) {
            if (err) {
                console.error(err)
                alert('Something went wrong!')
            } else if (imgData) {
                imageData = imgData
            }
        })
        setImageData(imageData)
    }

    const onVerifyCode = async () => {
        if (verificationCode2fa && verificationCode2fa.length === 6) {
            props.setLoading(true)
            apiService(
                (window.location.hostname === 'localhost' ? api_url : '') +
                '/restapi/client-save-2fa-secret',
                {
                    email: localStorage.getItem('user_name')
                        ? localStorage.getItem('user_name')
                        : props.merchantEmail,
                    // merchantId: localStorage.getItem('merchantId') ? localStorage.getItem('merchantId') : props.merchantData.merchantId,
                    user_email: localStorage.getItem('user_name')
                        ? localStorage.getItem('user_name')
                        : props.merchantEmail,
                    user2faToken: verificationCode2fa,
                    secret: secret.base32,
                    encoding: 'base32',
                    changeActivation: 'activate',
                },
                (data) => {
                    props.setLoading(false)
                    if (data.success === true) {
                        if (props.component === 'head') {
                            props.onVerificationSucess(
                                props.merchantData,
                                props.merchantEmail,
                            )
                        } else {
                            props.setIs2faActivated()
                            localStorage.setItem('2FAStatus', true)
                        }
                        props.onClose()
                    } else {
                        setError('Invalid verification code!')
                    }
                },
                (err) => {
                    props.setLoading(false)
                    alert('Something went wrong!')
                    console.log(err)
                },
            )
        } else {
            setError('Invalid token! 2FA token must have 6-digit only')
        }
    }

    const disabledVerify = verificationCode2fa.length !== 6
    let headerButtonStyle = useHeaderButtonStyle(
        colorMode,
        headerButtonDarkBgColour,
        headerDarkTextColour,
        headerButtonLightBgColour,
        headerLightTextColour,
    )

    return (
        <Dialog open={props.open} className="theme-modal">
            <div className="theme-modal-body">
                <div className="theme-modal-close">
                    <ClearIcon onClick={props.onClose} className="icon-close" />
                </div>
                <form>
                    <p className="theme-modal-title">Authenticator</p>
                    <div className="theme-modal-authenticator">
                        <img src={imageData}></img>
                    </div>
                    <p className="theme-modal-text">
                        Scan the QR Code in Googles authenticator app and the
                        input verification code.
                    </p>
                    <br />
                    <div className="theme-modal-field">
                        <p className="theme-modal-field-label">
                            Verification Code
                        </p>
                        <input
                            value={verificationCode2fa}
                            type="text"
                            className="theme-modal-field-input"
                            placeholder=""
                            onChange={(e) => {
                                setError('')
                                change2faVerificationCode(e.target.value)
                            }}
                        />
                        {error !== '' && (
                            <div className="errorMessage">{error}</div>
                        )}
                    </div>
                    <div className="theme-btn-block theme-modal-submit full-width">
                        <button
                            type="button"
                            disabled={disabledVerify}
                            className={`theme-btn ${disabledVerify ? '' : 'disabled'}`}
                            onClick={() => {
                                if (!disabledVerify) {
                                    onVerifyCode()
                                }
                            }}
                            style={{ ...headerButtonStyle }}
                        >
                            <p>Verify</p>
                        </button>
                    </div>
                </form>
            </div>
        </Dialog>
    )
}

export default JunoFaAuthenticator
