import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { apiService } from '../../../../common/apiCallService'
import { useSelector, useDispatch } from 'react-redux'
import TableComponent from '../../components/table/TableComponent'
import MediaQuery from 'react-responsive'
import {
    getCurrencySymbol,
    getStatus,
    renderExpandedComponent,
    toFixedTrunc,
    getBalanceSign,
    useJunoCommonFunctions,
} from '../../../helpers'
import * as actions from '../../../../Redux/actions'
import JncSectionTitle from '../../components/global/JncSectionTitle'
import Icon from '../../icons/Icon'
import _ from 'lodash'
import TransactionsMobile from './TransactionsMobile'
import JncTransactionFilters from './JncTransactionFilters'

export default function JncCryptoTransaction({
    isDashboard,
    showTablePagination,
    isBalancesTab,
}) {
    const data = useSelector((state) => state.juno.transactionsCrypto)
    const activeCurrencyPage = useSelector((state) => state.juno.activeCurrencyPage)
    const dispatch = useDispatch()
    const apiUrl = useSelector((state) => state.config.api_url)
    const clientData = useSelector((state) => state.juno.clientData)
    const { useWindowWidth } = useJunoCommonFunctions()

    const [isLoading, setIsLoading] = useState(true)

    const [transactionDialog, setTransactionDialog] = useState(false)
    const [transactionDialogData, setTransactionDialogData] = useState(null)
    const defaultFilter = {
        currency: [],
        type: '',
        from: '',
        to: '',
        status: '',
        page: 1,
        pageSize: window.innerWidth > 991 ? 10 : 5,
    }
    const [filter, setFilter] = useState(defaultFilter)
    const [totalRows, setTotalRows] = useState(0)

    const [mobileFiltersDialog, setMobileFiltersDialog] = useState(false)

    const windowWidth = useWindowWidth()

    const columns = [
        {
            name: 'Transaction type',
            selector: (row) =>
                row.type + (row.transferInOut ? ` (${row.transferInOut})` : ''),
            sortable: true,
            cell: (row) => (
                <div
                    data-tag="allowRowEvents"
                    className="no-wrap jncTable-type"
                >
                    <Icon id={_.camelCase(row.type)} />
                    {row.type +
                        (row.transferInOut ? ` (${row.transferInOut})` : '')}
                </div>
            ),
            width: '160px',
        },
        {
            name: 'Date',
            selector: (row) => row.createdDate,
            sortable: true,
            cell: (row) => (
                <div data-tag="allowRowEvents" className="">
                    {format(new Date(row.createdDate), 'dd/MM/y')}
                    <br />
                    {format(new Date(row.createdDate), 'HH:mm:ss')}
                </div>
            ),
        },
        {
            name: 'Transaction Id',
            selector: (row) => row.transactionId || 'N/A',
            sortable: false,
            cell: (row, index) => (
                <div
                    data-e2e={row.transactionId || 'N/A'}
                    data-e2e-row={index}
                    data-tag="allowRowEvents"
                    className=""
                >
                    {row.transactionId || 'N/A'}
                </div>
            ),
        },
        {
            name: 'Currency',
            selector: (row) => {
                if (row.type == 'FX') {
                    return `${row.transactionDetails[0].fromCurrency} - ${row.transactionDetails[0].toCurrency}`
                } else {
                    return row.currency
                }
            },
            sortable: true,
            width: '115px',
        },
        {
            name: 'Amount',
            sortable: true,
            width: '210px',
            cell: (row) => {
                let content = 'N/A'
                const transaction = row.transactionDetails[0]

                if (transaction) {
                    content = (
                        <div
                            data-tag="allowRowEvents"
                            className="jncTable-amount"
                        >
                            {getBalanceSign(row)}{' '}
                            {getCurrencySymbol(
                                row.currency || transaction.currency,
                            )}{' '}
                            {toFixedTrunc(transaction.cryptoAmount, 4)}
                            {/* {row.type !== 'Deposit' &&
                                row.transferInOut !== 'In' &&
                                RepeatActionBtn(row)} */}
                        </div>
                    )
                }
                return content
            },
        },
        {
            name: 'Status',
            selector: (row) => row.status.code,
            sortable: true,
            cell: (row) => (
                <div
                    data-tag="allowRowEvents"
                    className={`jncTable-status ${getStatus(row.status.code).toLowerCase().replace('/', '')}`}
                >
                    {getStatus(row.status.code)}
                </div>
            ),
        },
    ]

    const ExpandedComponent = ({ data }) => {
        return renderExpandedComponent(data)
    }

    const refetchData = () => {
        if (clientData) {
            apiService(
                (window.location.hostname === 'localhost' ? apiUrl : '') +
                '/restapi/get-client-transactions',
                {
                    client: clientData.clientId,
                    currencyType: activeCurrencyPage,
                    ...filter,
                    currency: filter.currency.length
                        ? filter.currency[0].text
                        : '',
                },
                async (data) => {
                    if (data) {
                        dispatch(
                            actions.setTransactionsData(
                                data.clientsTransactionData,
                            ),
                        )
                        setTotalRows(data.totalCount)
                        setIsLoading(false)
                    }
                },
                (err) => {
                    console.log(err)
                    setIsLoading(false)
                },
            )
        }
    }

    useEffect(() => {
        refetchData()
    }, [filter, clientData])

    function openTransactionDialog(data) {
        setTransactionDialogData(data)
        setTransactionDialog(true)
    }

    function closeTransactionDialog() {
        setTransactionDialog(false)
        setTransactionDialogData(null)
    }

    return (
        <div
            className={`jncSection jncTransactions ${isDashboard ? 'is-dashboard mb' : ''} ${isBalancesTab ? 'hidden' : ''}`}
        >
            <div
                className={`jncTable ${isDashboard ? 'is-dashboard' : ''} ${data.length < 1 ? 'no-data' : ''}`}
            >
                {isDashboard && (
                    <JncSectionTitle
                        title="Transaction history"
                        seeAllLink="/client/transactions"
                    />
                )}
                {!isDashboard && !isLoading && windowWidth > 991 && (
                    <JncTransactionFilters
                        filter={filter}
                        setFilter={setFilter}
                    />
                )}
                {!isDashboard && (
                    <button
                        onClick={() => setMobileFiltersDialog(true)}
                        type="button"
                        className="max-991 jncTransactions__filters-btn"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                        >
                            <path
                                d="M7.5 13.5H10.5V12H7.5V13.5ZM2.25 4.5V6H15.75V4.5H2.25ZM4.5 9.75H13.5V8.25H4.5V9.75Z"
                                fill="black"
                            />
                        </svg>
                        Filter
                    </button>
                )}
                <div className="jncTable-hover">
                    <MediaQuery minWidth={992}>
                        <TableComponent
                            columns={columns}
                            filteredData={isDashboard ? data.slice(0, 5) : data}
                            expandableRows={true}
                            expandableRowsComponent={ExpandedComponent}
                            expandableIcon={{}}
                            expandOnRowClicked={true}
                            showTablePagination={showTablePagination}
                            isLoading={isLoading}
                            noDataText={
                                data.length === 0
                                    ? "You don't have any transactions yet"
                                    : 'There are no transactions that match your criteria'
                            }
                            paginationServer
                            totalRows={totalRows}
                            handlePageChange={(page) =>
                                setFilter((pre) => ({ ...pre, page }))
                            }
                        />
                    </MediaQuery>
                    <MediaQuery maxWidth={991}>
                        <TransactionsMobile
                            filteredData={data}
                            isLoading={isLoading}
                            noDataText={
                                data.length === 0
                                    ? "You don't have any transactions yet"
                                    : 'There are no transactions that match your criteria'
                            }
                            showTablePagination={showTablePagination}
                            openTransactionDialog={openTransactionDialog}
                            totalRows={totalRows}
                            handlePageChange={(page) =>
                                setFilter((pre) => ({ ...pre, page }))
                            }
                        />
                    </MediaQuery>
                </div>
            </div>
        </div>
    )
}
