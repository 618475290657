import React from 'react'
import { useSelector } from 'react-redux'
import { toFixedTrunc } from '../../../helpers'
import { getCurrencySymbol } from '../../../helpers'
import { cryptoCurrencyIcon } from '../../constant'

export default function JncCryptoCard({ data }) {
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)
    const balanceAmount = toFixedTrunc(data.balanceAmount, 6)
        .toString()
        .split('.')

    return (
        <div className="jncBalanceCard jncCard">
            <div className="jncBalanceCard__curr">
                <div>
                    <p className="min-768 jncBalanceCard__curr-value">
                        <span
                            data-e2e-currency={`${getCurrencySymbol(data.currencyShortName)}`}
                            data-e2e={`${balanceAmount[0]}`}
                            data-e2e-amountdecimal={`${balanceAmount[1]}`}
                        >
                            {getCurrencySymbol(data.currencyShortName)}&nbsp;
                        </span>
                        {balanceAmount[0]}{balanceAmount[1] && `.${balanceAmount[1]}`}
                    </p>
                    <p className='text-2xl pt-3 min-768'>
                        $ {toFixedTrunc(data?.convertedUSDAmount,2) || 0}
                    </p>
                    <p className="max-767 jncBalanceCard__curr-value">
                        {toFixedTrunc(data.balanceAmount, 6)}{' '}
                        {data.currencyShortName}
                    </p>
                    <p className="jncBalanceCard__curr-percent">
                        {toFixedTrunc(data.holdingPercentage, 2)}%
                    </p>
                </div>
                <div className="jncBalanceCard__curr-img">
                    <img src={`${imageBaseUrl}${cryptoCurrencyIcon[data.currencyShortName]}`} />
                    <p className="max-767">{data.currencyFullName}</p>
                </div>
            </div>
        </div>
    )
}
