import React, { useState } from 'react'
import { format } from 'date-fns'
import StatementsTableActions from './StatementsTableActions'
import MediaQuery from 'react-responsive'
import TableComponent from '../../components/table/TableComponent'
import TableComponentMobile from '../../components/table/TableComponentMobile'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import JncStatementsEditModal from './JncStatementsEditModal'
import { toFixedTrunc, getCurrencySymbol } from '../../../helpers'
import JncSectionTitle from '../../components/global/JncSectionTitle'
import JncAccountConfirmation from '../../templates/JncAccountConfirmation'
import { useJunoCommonFunctions } from '../../../helpers'
import JncPageTitle from '../../components/global/JncPageTitle'

export default function JncStatements({
    isDashboardPage,
    showTablePagination,
    activeTab,
    headerAction
}) {
    const activeCurrencyPage = useSelector((state) => state.juno.activeCurrencyPage)
    const { getStatements } = useJunoCommonFunctions()
    const [statementModal, setStatementModal] = useState(false)
    const [isEditStatementModal, setIsEditStatementModal] = useState(false)
    const [editStatementData, setEditStatementData] = useState(null)
    const clientData = useSelector((state) => state.juno.clientData)
    const readOnly =
        clientData && clientData.readOnly ? clientData.readOnly : false
    const statementsData = useSelector((state) => state.juno.statements)

    const columns = [
        {
            name: 'Created',
            selector: (row) => row.createdAt,
            sortable: true,
            cell: (row) => (
                <div className="">
                    {format(new Date(row.createdAt), 'dd/MM/y')}
                    <br />
                    {format(new Date(row.createdAt), 'HH:mm:ss')}
                </div>
            ),
        },
        {
            name: 'Currency',
            selector: (row) => row.currencyShortName,
            sortable: true,
            cell: (row) => (
                <div className="jncTable-currency">
                    <div>{row.currencyShortName}</div>
                </div>
            ),
        },
        {
            name: 'Opening balance',
            selector: (row) => row.openingBalance,
            sortable: true,
            cell: (row) => (
                <div>
                    <span className="no-color-change">
                        {row.currencyType === 'fiat' &&
                            getCurrencySymbol(row.currencyShortName)}
                        &ensp;
                    </span>
                    {toFixedTrunc(
                        row.openingBalance,
                        row.currencyType == 'crypto' ? 8 : 2,
                    )}
                </div>
            ),
        },
        {
            name: 'Closing balance',
            selector: (row) => row.closingBalance,
            sortable: true,
            cell: (row) => (
                <div>
                    <span className="no-color-change">
                        {row.currencyType === 'fiat' &&
                            getCurrencySymbol(row.currencyShortName)}
                        &ensp;
                    </span>
                    {toFixedTrunc(
                        row.closingBalance,
                        row.currencyType == 'crypto' ? 8 : 2,
                    )}
                </div>
            ),
        },
        {
            name: 'Actions',
            cell: (row) => (
                <StatementsTableActions
                    openEditStatementModal={openEditStatementModal}
                    row={row}
                    readOnly={readOnly}
                />
            ),
        },
    ]

    function onCloseStatementModal() {
        setStatementModal(false)
        setTimeout(() => {
            setIsEditStatementModal(false)
        }, 300)
    }

    function openEditStatementModal(row) {
        setIsEditStatementModal(true)
        setStatementModal(true)
        setEditStatementData(row)
    }

    useEffect(() => {
        if (clientData) {
            getStatements()
        }
        if (headerAction) {
            setStatementModal(true)
        }
    }, [clientData, activeCurrencyPage, headerAction])

    return (
        <div
            className={`jncStatements jncSection ${
                isDashboardPage ? 'is-dashboard' : ''
            }`}
        >
            <div
                className={`jncTable ${isDashboardPage ? 'is-dashboard' : ''}`}
            >
                <div className={`${!isDashboardPage ? 'min-768' : ''}`}>
                    <JncSectionTitle
                        title="Statements"
                        seeAllLink={isDashboardPage ? '/client/profile' : false}
                    >
                        <button
                            onClick={() => setStatementModal(true)}
                            disabled={readOnly}
                            className={`${readOnly && 'jnc-btn-disable'}`}
                            data-e2e="add-new-statement-button"
                        >
                            Add new statement
                        </button>
                    </JncSectionTitle>
                </div>
                <MediaQuery minWidth={992}>
                    <TableComponent
                        isLoading={false}
                        showTablePagination={showTablePagination}
                        columns={columns}
                        filteredData={
                            isDashboardPage
                                ? [...statementsData].splice(0, 5)
                                : statementsData
                        }
                        noDataText="You don't have any statements yet"
                    />
                </MediaQuery>

                <MediaQuery maxWidth={991}>
                    <TableComponentMobile
                        isLoading={false}
                        valuesToDisplay={'statements'}
                        filteredData={statementsData}
                        openEditStatementModal={openEditStatementModal}
                        actions={'statements'}
                        showTablePagination={showTablePagination}
                        noDataText="You don't have any statements yet"
                    />
                </MediaQuery>

                {statementsData && statementsData.length < 1 && (
                    <button
                        disabled={readOnly}
                        onClick={() => setStatementModal(true)}
                        className="jncAddItem max-767"
                        data-e2e="add-new-statement-btn"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            fill="none"
                        >
                            <path
                                d="M24 4C12.954 4 4 12.954 4 24C4 35.046 12.954 44 24 44C35.046 44 44 35.046 44 24C44 12.954 35.046 4 24 4ZM34 26H26V34H22V26H14V22H22V14H26V22H34V26Z"
                                fill="#344054"
                            />
                        </svg>
                        <p data-e2e="add-bank-account-btn">Add new statement</p>
                    </button>
                )}

                {!isDashboardPage && (
                    <JncAccountConfirmation activeTab={activeTab} />
                )}
                <JncStatementsEditModal
                    isEdit={isEditStatementModal}
                    open={statementModal}
                    onClose={onCloseStatementModal}
                    updateStatements={getStatements}
                    editStatementData={editStatementData}
                    fullScreen={true}
                />
            </div>
        </div>
    )
}
