import React, { useEffect } from 'react'
import JncLayout from '../layouts/JncLayout'
import JncTransactions from '../tables/transactions/JncTransactions'
import { useDispatch, useSelector } from 'react-redux'
import { useJunoCommonFunctions } from '../../helpers'
import { apiService } from '../../../common/apiCallService'
import * as actions from '../../../Redux/actions'

export default function JunoClientTransactionsPage() {
    const { getBalances, getSavedAccounts } = useJunoCommonFunctions()
    const dispatch = useDispatch()
    const api_url = useSelector((state) => state.config.api_url)
    const clientData = useSelector((state) => state.juno.clientData)

    const getClientFeeSetting = () => {
        apiService(
            (window.location.hostname === 'localhost' ? api_url : '') +
                '/restapi/get-client-fee-setting',
            {
                clientId: clientData.clientId,
            },
            async (data) => {
                if (data) {
                    dispatch(actions.setClientTransferFee(data))
                }
            },
            (err) => {
                console.log(err)
            },
        )
    }

    useEffect(() => {
        if (clientData) {
            getBalances()
            getClientFeeSetting()
            getSavedAccounts()
        }
    }, [clientData])

    return (
        <JncLayout>
            <div className="jncPage">
                <JncTransactions showTablePagination={true} />
            </div>
        </JncLayout>
    )
}
