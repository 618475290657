import React from 'react'
import JunoClientActionModal from '../../components/table/JunoClientActionModal'
import TransactionMobileDialogDeposit from './TransactionMobileDialogDeposit'
import TransactionMobileDialogWithdraw from './TransactionMobileDialogWithdraw'
import TransactionMobileDialogOut from './TransactionMobileDialogOut'
import TransactionMobileDialogTransfer from './TransactionMobileDialogTransfer'
import TransactionMobileDialogFx from './TransactionMobileDialogFx'
import Icon from '../../icons/Icon'

export default function TransactionMobileDialog({ onClose, open, data }) {
    function DialogContentComponent() {
        return (
            <div>
                {data ? (
                    <div>
                        {data.type === 'Deposit' && (
                            <TransactionMobileDialogDeposit data={data} />
                        )}
                        {data.type === 'Withdrawal' && (
                            <TransactionMobileDialogWithdraw data={data} />
                        )}
                        {data.type === 'Payment Out' && (
                            <TransactionMobileDialogOut data={data} />
                        )}
                        {data.type === 'FX' && (
                            <TransactionMobileDialogFx data={data} />
                        )}
                        {data.type === 'Transfer' && (
                            <TransactionMobileDialogTransfer data={data} />
                        )}
                    </div>
                ) : null}
            </div>
        )
    }

    function DialogActionsComponent() {
        return (
            // <button type="button" disabled={readOnly} onClick={() => openRepeatModal(row)} className="jncRepeatBtn">
            // 	<Icon id="repeatAction" />
            // </button>
            <div className="jncTransactionModal__repeat">
                {/* <button type="button" className="jncRepeatBtn">
					<Icon id="repeatAction" />
				</button>
				<p>Repeat</p> */}
            </div>
        )
    }

    return (
        <JunoClientActionModal
            onClose={onClose}
            customDialogTitle="transaction-mobile"
            open={open}
            dialogContent={DialogContentComponent()}
            dialogActions={DialogActionsComponent()}
            transaction={data}
            fullScreen={true}
        ></JunoClientActionModal>
    )
}
