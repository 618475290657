import React, { useState, useEffect } from 'react'
import JncLayout from '../layouts/JncLayout'
import { useSelector, useDispatch } from 'react-redux'
import JncBalances from '../components/dashboard/JncBalances'
import JncAccounts from '../components/dashboard/JncAccounts'
import * as actions from '../../../Redux/actions'
import { apiService } from '../../../common/apiCallService'
import JunoClientSnackbar from '../components/global/JunoClientSnackbar'
import JncTransactions from '../tables/transactions/JncTransactions'
import DashboardActions from '../components/dashboard/DashboardActions'
import { useJunoCommonFunctions } from '../../helpers'
import JncStatements from '../tables/statements/JncStatements'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import { NavLink } from 'react-router-dom'
import DashboardHeader from "../components/dashboard/DashboardHeader";
import JncCryptoDashboardPage from './JncCryptoDashboardPage'

export default function JncDashboardPage() {
    const { getBalances, getSavedAccounts, getStatements, getCryptoBalances } =
        useJunoCommonFunctions()
    const activeCurrencyPage = useSelector((state) => state.juno.activeCurrencyPage)
    const clientData = useSelector((state) => state.juno.clientData)
    const savedAccounts = useSelector((state) => state.juno.savedAccounts)
    const statementsData = useSelector((state) => state.juno.statements)
    const dispatch = useDispatch()
    const api_url = useSelector((state) => state.config.api_url)
    const [isBalancesTab, setIsBalanceTab] = useState(true)


    useEffect(() => {
        if (clientData) {
            if (activeCurrencyPage === "crypto") {
                getCryptoBalances()
            }
            getBalances()
            getSavedAccounts()
            getClientFeeSetting()
            getStatements()
        }
    }, [clientData, activeCurrencyPage])

    const getClientFeeSetting = () => {
        apiService(
            (window.location.hostname === 'localhost' ? api_url : '') +
            '/restapi/get-client-fee-setting',
            {
                clientId: clientData.clientId,
            },
            async (data) => {
                if (data) {
                    dispatch(actions.setClientTransferFee(data))
                }
            },
            (err) => {
                console.log(err)
            },
        )
    }
    return (
        <JncLayout>
            {activeCurrencyPage === "fiat" ?
                <div className="jncPage">
                    <AnimationOnScroll
                        animateIn="animate__fadeInUpCustom"
                        duration={1.2}
                    >
                        <DashboardHeader />
                        <div className="jncPage__order">
                            <div className="max-767 jncDashbTabs savedAccounts">
                                <div className="savedAccounts-tabs">
                                    <button
                                        type="button"
                                        className={`${isBalancesTab && 'active'}`}
                                        onClick={() => setIsBalanceTab(true)}
                                    >
                                        Balances
                                    </button>
                                    <button
                                        type="button"
                                        className={`${!isBalancesTab && 'active'}`}
                                        onClick={() => setIsBalanceTab(false)}
                                    >
                                        Transaction history
                                    </button>
                                </div>
                            </div>
                            <JncBalances
                                isDashboardPage={true}
                                isBalancesTab={isBalancesTab}
                            />
                            <JncTransactions
                                showTablePagination={false}
                                isDashboard={true}
                                isBalancesTab={isBalancesTab}
                            />
                            <NavLink
                                className="jncDashbTabs__seeAll max-767"
                                to={
                                    isBalancesTab
                                        ? '/client/balances'
                                        : '/client/transactions'
                                }
                            >
                                See All
                            </NavLink>
                            <DashboardActions />
                            {savedAccounts && savedAccounts.length > 0 && (
                                <JncAccounts isDashboardPage={true} />
                            )}
                            {statementsData && statementsData.length > 0 && (
                                <JncStatements
                                    showTablePagination={false}
                                    isDashboardPage={true}
                                />
                            )}
                        </div>
                    </AnimationOnScroll>
                    <JunoClientSnackbar />
                </div>
                :
                <JncCryptoDashboardPage />
            }

        </JncLayout>
    )
}
