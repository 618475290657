import React from 'react'
import { useSelector } from 'react-redux'

const ExchangeConfirmation = ({ formData }) => {
    const { balancesCrypto } = useSelector(
        (state) => state.juno
    )
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)
    const fromCryptoIcon = balancesCrypto.find(
        (x) => x.currencyShortName === formData.fromCryptoId,
    ).currencyIcon

    const toCryptoIcon = balancesCrypto.find(
        (x) => x.currencyShortName === formData.toCryptoId,
    ).currencyIcon

    return (
        <>
            <div className="border rounded-lg p-3">
                <h1 className="text-base font-semibold">
                    Transaction information:
                </h1>
                <div className="mt-5">
                    <p className="text-sm font-normal text-[#51525C] ">From:</p>
                    <div className="flex justify-between items-center">
                        <div className="flex items-center">
                            <img src={`${imageBaseUrl}${fromCryptoIcon}`} width={24} height={24} alt="cryptoIcon" className="" />
                            <span className="text-xl font-normal text-[#26272B]">
                                {formData.fromCryptoId}
                            </span>
                        </div>
                        <p className="text-xl font-semibold text-[#26272B] ">
                            {formData.fromCryptoAmount} ({formData.fromCryptoId})
                        </p>
                    </div>
                </div>
                <hr className="my-3" />
                <div className="">
                    <p className="text-sm font-normal text-[#51525C] ">TO:</p>
                    <div className="flex justify-between items-center">
                        <div className="flex items-center">
                            <img src={`${imageBaseUrl}${toCryptoIcon}`} width={24} height={24} alt="cryptoIcon" className="" />
                            <span className="text-xl font-normal text-[#26272B]">
                                {formData.toCryptoId}
                            </span>
                        </div>
                        <p className="text-xl font-semibold text-[#26272B]">
                            {formData.toCryptoAmount} ({formData.toCryptoId})
                        </p>
                    </div>
                </div>
                <hr className="my-3" />
                <div className="flex justify-between items-center">
                    <p className="text-base font-normal text-[#51525C] ">
                        Rate:
                    </p>
                    <p className="text-base font-medium text-[#18181B] ">
                        1USD = 1USDT
                    </p>
                </div>
                <div className="mt-3 flex justify-between items-center">
                    <p className="text-base font-normal text-[#51525C] ">
                        Fee:
                    </p>
                    <p className="text-base font-medium text-[#18181B]">
                        1USD = 1USDT
                    </p>
                </div>
                <hr className="my-3" />
                <div className="flex justify-between items-center">
                    <p className="text-base font-normal text-[#51525C] ">
                        Total:
                    </p>
                    <p className="text-base font-medium text-[#18181B]">
                        302 (USDT)
                    </p>
                </div>
            </div>
        </>
    )
}

export default ExchangeConfirmation
