import React from 'react'
import { Select, MenuItem } from '@mui/material'
import { useSelector } from 'react-redux'
import { cryptoCurrencyIcon } from '../../constant'

export const JncCustomSelect = ({
    value,
    onChange,
    options,
    className,
    inputProps,
}) => {
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)

    const renderValue = (selected) => {
        const selectedOption = options.find(
            (option) => option.currencyShortName === selected
        )
        return selectedOption ? (
            <div className="flex items-center gap-1">
                <img
                    src={`${imageBaseUrl}${cryptoCurrencyIcon[selectedOption.currencyShortName] || selectedOption.currencyIcon}`}
                    alt={selectedOption.currencyShortName}
                    className="w-6 h-6"
                />
                {selectedOption.currencyShortName}

            </div>
        ) : (
            ''
        )
    }
    return (
        <Select
            value={value}
            onChange={onChange}
            className={`bg-input !rounded-r-3xl w-full max-sm:w-[200px] ${className}`}
            inputProps={inputProps}
            renderValue={renderValue}
            MenuProps={{
                PaperProps: {
                    style: {
                        maxHeight: 240,
                    },
                },
            }}
        >
            {options.map((option) => (
                <MenuItem
                    key={option.currencyShortName}
                    value={option.currencyShortName}
                >
                    <div className="flex items-center">
                        <img
                            src={`${imageBaseUrl}${cryptoCurrencyIcon[option.currencyShortName] || option.currencyIcon}`}
                            alt={option.currencyShortName}
                            className="w-6 h-6 mr-2"
                        />
                        {`${option.currencyFullName || option.blockchain} (${option.currencyShortName})`}
                    </div>
                </MenuItem>
            ))}
        </Select>
    )
}
