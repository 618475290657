import React, { useEffect } from 'react'
import JncLayout from '../layouts/JncLayout'
import JncPageTitle from '../components/global/JncPageTitle'
import { useSelector, useDispatch } from 'react-redux'
import JncBalances from '../components/dashboard/JncBalances'
import { apiService } from '../../../common/apiCallService'
import * as actions from '../../../Redux/actions'
import { useJunoCommonFunctions } from '../../helpers'
import { AnimationOnScroll } from 'react-animation-on-scroll'

export default function JncBalancesPage() {
    const { getBalances, getSavedAccounts } = useJunoCommonFunctions()
    const clientData = useSelector((state) => state.juno.clientData)
    const dispatch = useDispatch()
    const api_url = useSelector((state) => state.config.api_url)

    useEffect(() => {
        if (clientData) {
            getBalances()
            getClientFeeSetting()
        }
    }, [clientData])

    const getClientFeeSetting = () => {
        apiService(
            (window.location.hostname === 'localhost' ? api_url : '') +
                '/restapi/get-client-fee-setting',
            {
                clientId: clientData.clientId,
            },
            async (data) => {
                if (data) {
                    dispatch(actions.setClientTransferFee(data))
                }
            },
            (err) => {
                console.log(err)
            }
        )
    }

    useEffect(() => {
        getSavedAccounts()
    }, [])

    return (
        <JncLayout>
            <div className="jncPage">
                {/* <AnimationOnScroll animateIn="animate__fadeInUpCustom" duration={1.2}> */}
                <JncPageTitle title="Balances" />
                <JncBalances isBalancesTab={true} />
                {/* </AnimationOnScroll> */}
            </div>
        </JncLayout>
    )
}
