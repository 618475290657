import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import Icon from '../../icons/Icon'
import { toFixedTrunc, getStatus } from '../../../helpers'
import format from 'date-fns/format'
import Pagination from '../../components/pagination/Pagination'
import JncLoading from '../../components/pagination/JncLoading'
import JncEmptyData from '../../components/table/JncEmptyData'

const PageSize = 5

export default function TransactionsMobile({
    filteredData,
    isLoading,
    noDataText,
    showTablePagination,
    openTransactionDialog,
    totalRows,
    handlePageChange,
}) {
    const [currentPage, setCurrentPage] = useState(1)
    const [paginationData, setPaginationData] = useState([])

    useEffect(() => {
        const firstPageIndex = (currentPage - 1) * PageSize
        const lastPageIndex = firstPageIndex + PageSize
        const res = filteredData.slice(firstPageIndex, lastPageIndex)
        setPaginationData(res)
    }, [currentPage])

    useEffect(() => {
        const firstPageIndex = 0
        const lastPageIndex = firstPageIndex + PageSize
        const res = filteredData.slice(firstPageIndex, lastPageIndex)
        setPaginationData(res)
    }, [filteredData])

    function renderStatus(x) {
        return (
            <div className="d-flex">
                <div
                    className={`jncTransactionsList__status ${getStatus(
                        x.status.code
                    )
                        .toLowerCase()
                        .replace('/', '')}`}
                >
                    {getStatus(x.status.code)}
                </div>
            </div>
        )
    }
    const handlePaginationChange = (page) => {
        if (handlePageChange) {
            handlePageChange(page)
        }
        setCurrentPage(page)
    }

    return (
        <div>
            {isLoading ? (
                <JncLoading />
            ) : (
                <div>
                    {paginationData.length < 1 && (
                        <JncEmptyData text={noDataText} />
                    )}
                    <div className="jncTransactionsList">
                        {paginationData.map((x) => {
                            const depositAmount =
                                x.type === 'Deposit' &&
                                x.transactionDetails[0].amount -
                                    x.transactionFee
                            return (
                                <button
                                    onClick={() => openTransactionDialog(x)}
                                    type="button"
                                    key={x.createdDate}
                                    className="jncTransactionsList__item"
                                >
                                    <div className="jncTransactionsList__type">
                                        <div className="jncTransactionsList__type-icon">
                                            <Icon id={_.camelCase(x.type)} />
                                        </div>
                                        <div className="">
                                            <p className="jncTransactionsList__text">
                                                {x.type}
                                            </p>
                                            <span className="jncTransactionsList__date">
                                                {format(
                                                    new Date(
                                                        x.createdDate.toString()
                                                    ),
                                                    'dd.MM.y'
                                                )}
                                                &nbsp;
                                                {format(
                                                    new Date(
                                                        x.createdDate.toString()
                                                    ),
                                                    'HH:mm'
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="">
                                        {x.type == 'FX' && (
                                            <p className="jncTransactionsList__text t-right">
                                                {toFixedTrunc(
                                                    x.transactionDetails[0]
                                                        .fromAmount,
                                                    2
                                                )}{' '}
                                                {
                                                    x.transactionDetails[0]
                                                        .fromCurrency
                                                }
                                                &ensp;-&ensp;
                                                {toFixedTrunc(
                                                    x.transactionDetails[0]
                                                        .toAmount,
                                                    2
                                                )}{' '}
                                                {
                                                    x.transactionDetails[0]
                                                        .toCurrency
                                                }
                                            </p>
                                        )}
                                        {x.type != 'FX' && (
                                            <p className="jncTransactionsList__text t-right">
                                                {x.type !== 'Deposit'
                                                    ? toFixedTrunc(
                                                          x
                                                              .transactionDetails[0]
                                                              .amount,
                                                          2
                                                      )
                                                    : toFixedTrunc(
                                                          depositAmount,
                                                          2
                                                      )}
                                                {x.transactionDetails[0]
                                                    .currency || x.currency}
                                            </p>
                                        )}
                                        {renderStatus(x)}
                                    </div>
                                </button>
                            )
                        })}
                    </div>
                    {showTablePagination && (
                        <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={totalRows || filteredData.length}
                            pageSize={PageSize}
                            onPageChange={handlePaginationChange}
                        />
                    )}
                </div>
            )}
        </div>
    )
}
