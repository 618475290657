import Dialog from '@mui/material/Dialog'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { apiService } from '../../../../common/apiCallService'
import JncAuthClose from '../global/auth/JncAuthClose'
import JncAuthTitle from '../global/auth/JncAuthTitle'
import AuthCode from 'react-auth-code-input'
import JncAuthBottom from '../global/auth/JncAuthBottom'

const JunoFaVerification = (props) => {
    const api_url = useSelector((state) => state.config.api_url)
    const [error, setError] = useState('')
    const [verificationCode2fa, change2faVerificationCode] = useState('')
    const headerLogo = sessionStorage.getItem("logo")
    const logoWidth = sessionStorage.getItem("logoWidth")
    const imageBaseURl = useSelector((state) => state.config.image_base_url)

    const onVerifyCode = () => {
        if (verificationCode2fa && verificationCode2fa.length === 6) {
            props.setLoading(true)
            apiService(
                (window.location.hostname === 'localhost' ? api_url : '') +
                '/restapi/client-verify-2fa-token',
                {
                    email: localStorage.getItem('user_name')
                        ? localStorage.getItem('user_name')
                        : props.clientEmail,
                    user2faToken: verificationCode2fa,
                    authenticator: false,
                    changeActivation: props.is2faActivated
                        ? props.is2faActivated
                        : false,
                },
                (data) => {
                    props.setLoading(false)
                    if (data.success === true) {
                        if (props.is2faActivated && props.is2faActivated) {
                            localStorage.setItem('2FAStatus', false)
                            props.setIs2faActivated()
                        } else {
                            props.onVerificationSucess(
                                props.merchantData,
                                props.clientEmail,
                            )
                        }
                        props.onClose()
                    } else {
                        setError('Invalid verification code!')
                    }
                },
                (err) => {
                    props.setLoading(false)
                    alert('Something went wrong!')
                    console.log(err)
                },
            )
        } else {
            setError('Invalid token! 2FA token must have 6-digit only')
        }
    }

    const onInputChange = (value) => {
        setError('')
        change2faVerificationCode(value)
    }

    const disabledVerify = verificationCode2fa.length !== 6

    return (
        <Dialog
            open={props.open}
            className="jncAuth"
            onClose={props.onClose}
            fullScreen
        >
            <div className="jncAuth__body bg-brand-black">
                <div>
                    <JncAuthClose onClose={props.onClose} />
                    <div className=' jncAuth__container p-8 !max-w-[644px] bg-white rounded-lg'>
                        <div className="d-flex justify-center items-center flex-col m-auto">
                            <div className="mb-[20px]">
                                {headerLogo && headerLogo !== 'null' ?
                                    <img src={headerLogo} alt="logo" width={logoWidth || "80"} height="auto" />
                                    :
                                    <img className='w-36' src={`${imageBaseURl}/landingpage/dark-logo.svg`} alt="logo" />
                                }
                            </div>
                            <div className="max-w-[360px] mb-0">
                                <JncAuthTitle
                                    title="Two-factor authentification"
                                    subtitle="Please open your authenticator app and enter the verification code below."
                                />
                            </div>
                            {error !== '' && (
                                <div
                                    className="alert alert-danger"
                                    role="alert"
                                >
                                    {error}
                                </div>
                            )}
                        </div>
                        <form>
                            <div className="authCode">
                                <AuthCode
                                    placeholder="0"
                                    onChange={(e) => onInputChange(e)}
                                />
                            </div>
                            <div className="jncAuth__container">
                                <button
                                    disabled={disabledVerify}
                                    type="button"
                                    className="jncAuth__Button w-full rounded-full flex justify-center h-11 items-center text-white font-semibold cursor-pointer full-width !bg-brand-black hover:bg-brand-gray"
                                    onClick={() => {
                                        if (!disabledVerify) {
                                            onVerifyCode()
                                        }
                                    }}
                                >
                                    <p>Log In</p>
                                </button>
                            </div>
                            {/* <p className="font-medium text-sm text-center pt-4">
                                Lost your authenticator device? <a className='text-[#1570EF] cursor-pointer'>Use recovery code instead</a>
                            </p> */}
                        </form>
                    </div>
                </div>
                <JncAuthBottom />
            </div>
        </Dialog>
    )
}

export default JunoFaVerification
