import React from 'react'
import CopyButton from '../../components/global/CopyButton'
import { toFixedTrunc, getCurrencySymbol, getStatus } from '../../../helpers'
import { ErrorTransactions } from '../../constant'

export default function WithdrawalCryptoExpanded(data) {
    const details = data && data.transactionDetails[0]
    const transactionFee = data && data.transactionFee
    return (
        <div className="jncExpanded">
            <div className="jncExpanded-grid">
                {details.fromAddress && (
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-label">From</p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p className="is-ellipsis">{details.fromAddress}</p>
                            <CopyButton copyValue={details.fromAddress} />
                        </div>
                    </div>
                )}
                {details.toAddress && (
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-label">To</p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p className="is-ellipsis">{details.toAddress}</p>
                            <CopyButton copyValue={details.toAddress} />
                        </div>
                    </div>
                )}
                {details.Txid && (
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-label">Txid</p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p className="is-ellipsis">{details.Txid}</p>
                            <CopyButton copyValue={details.Txid} />
                        </div>
                    </div>
                )}
                <div className="jncExpanded-block">
                    <p className="jncExpanded-label">Amount</p>
                    <div className="jncExpanded-block-copy jncExpanded-text">
                        <p>
                            {getCurrencySymbol(details.currency) || getCurrencySymbol(details.cryptoId) || "N/A"}
                            {toFixedTrunc(details.amount, 2) || toFixedTrunc(details.cryptoAmount, 2) || "-"}{' '}
                        </p>
                    </div>
                </div>
                <div className="jncExpanded-block">
                    <p className="jncExpanded-label">
                        Balance after transaction
                    </p>
                    {data.currencyType === 'crypto' && (
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>
                                {details.cryptoId}{' '}
                                {toFixedTrunc(
                                    data.balanceAfterTransaction,
                                    8,
                                )}
                            </p>
                            <CopyButton
                                copyValue={`${details.cryptoId} ${toFixedTrunc(data.balanceAfterTransaction, 8)}`}
                            />
                        </div>
                    )}
                </div>
                <div className="jncExpanded-block">
                    <p className="jncExpanded-label">Transaction Hash</p>
                    <div className="jncExpanded-block-copy jncExpanded-text">
                        <p>{details.transactionHash || "-"}</p>
                        <CopyButton copyValue={details.transactionHash} />
                    </div>
                </div>
                {ErrorTransactions.includes(data.status.code) && (
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-failed">
                            {getStatus(data.status.code)}:
                        </p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>{data.status.message}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
