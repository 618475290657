import React from 'react'
import JncLayout from '../layouts/JncLayout'
import JncAccounts from '../components/dashboard/JncAccounts'

export default function JncAccountsPage() {
    return (
        <JncLayout>
            <div className="jncPage">
                <JncAccounts />
            </div>
        </JncLayout>
    )
}
