import React, { useEffect, useState } from 'react'
import JunoClientActionModal from '../../../../components/table/JunoClientActionModal'
import JncButton from '../../../../components/global/JncButton'
import { useSelector } from 'react-redux'
import { JncCustomSelect } from '../../../../components/global/JncCustomSelect'
import { apiService } from '../../../../../../common/apiCallService'
import SellConfirm from './confirmModal/SellConfirm'
import { getCurrencySymbol, removeCommas, toFixedTrunc, useJunoCommonFunctions, validateAmount } from '../../../../../helpers'

const BalanceCryptoSell = (props) => {
    const { onClose, open } = props
    const apiUrl = useSelector((state) => state.config.api_url)
    const clientData = useSelector((state) => state.juno?.clientData)
    const { balancesCurrencies, balancesCrypto } = useSelector(
        (state) => state.juno
    )
    const { handleCryptoExchangeRate, getCryptoBalances } = useJunoCommonFunctions()
    const [isConfirmModal, setIsConfirmModal] = useState(false)
    const [amountError, setAmountError] = useState('')
    const [exchangeRate, setExchangeRate] = useState(1)
    const [formData, setFormData] = useState({
        cryptoAmount: null,
        fiatAmount: null,
        cryptoId: 'ETH',
        fiatCurrency: 'USD',
    })

    const handleClose = () => {
        onClose()
        setIsConfirmModal(false)
        setFormData({
            cryptoAmount: null,
            fiatAmount: null,
            cryptoId: 'ETH',
            fiatCurrency: 'USD',
        })
        setAmountError('')
    }

    const handleAmountChange = (e) => {
        if (exchangeRate) {
            let cryptoAmount, fiatAmount, amountError
            const name = e.target.name
            const value = e.target.value

            if (name === 'fiatAmount') {
                cryptoAmount = exchangeRate && removeCommas(value) * exchangeRate
                cryptoAmount = toFixedTrunc(cryptoAmount, 2)
                fiatAmount = value
                amountError = validateAmount(
                    removeCommas(cryptoAmount),
                    0,
                    formData.cryptoId,
                    balancesCrypto
                )
            } else if (name === 'cryptoAmount') {
                const fixedRate = 1 / exchangeRate
                fiatAmount = fixedRate && removeCommas(value) * fixedRate
                fiatAmount = toFixedTrunc(fiatAmount, 2)
                cryptoAmount = value
                amountError = validateAmount(
                    value,
                    0,
                    formData.cryptoId,
                    balancesCrypto
                )
            }
            setFormData({ ...formData, cryptoAmount, fiatAmount })
            setAmountError(amountError)
        }
    }

    const createCryptoTransaction = () => {
        const blockchain = balancesCrypto.find(crypto => crypto.currencyShortName === formData.cryptoId)?.blockchain
        const payload = {
            currencyType: 'crypto',
            type: 'Sell',
            clientId: clientData?.clientId,
            transactionEmail: localStorage.getItem('user_name'),
            transactionDetails: {
                cryptoAmount: parseFloat(removeCommas(formData.cryptoAmount)).toFixed(2),
                fiatAmount: parseFloat(removeCommas(formData.fiatAmount)).toFixed(2),
                cryptoId: formData.cryptoId,
                blockchain,
                fiatCurrency: formData.fiatCurrency,
                fxrate: 0.8386378,
                // "transactionFee": "0.83"
            },
        }
        apiService(
            (window.location.hostname === 'localhost' ? apiUrl : '') +
            '/restapi/create-client-transaction-crypto',
            payload,
            async (data) => {
                getCryptoBalances()
                handleClose()
            },
            (err) => {
                console.log(err)
            },
        )
    }

    const feePercentage = 0.2

    const handleExchangeRate = async () => {
        const payload = {
            cryptoList: [formData.cryptoId],
            fiatAmount: 1,
            fiatCurrency: formData.fiatCurrency,
            exchangeConversionType: 'fiat-to-crypto',
        }
        const response = await handleCryptoExchangeRate(payload)
        setExchangeRate(response.cryptoAmounts[formData.cryptoId])
    }


    useEffect(() => {
        if (formData.cryptoId && formData.fiatCurrency && open) {
            handleExchangeRate()
        }
    }, [formData, open])


    const dialogContentComponent = () => {
        return (
            <>
                {!isConfirmModal ?
                    <>
                        <div className="mb-[30px] flex justify-between items-center max-sm:flex-wrap">
                            <label className="text-base font-normal text-start">
                                Sell:
                            </label>
                            <div className="max-sm:pt-3 flex items-center max-sm:w-full">
                                <input
                                    type="text"
                                    name='cryptoAmount'
                                    data-e2e="sell-field"
                                    onChange={handleAmountChange}
                                    value={formData.cryptoAmount}
                                    className="bg-input rounded-l-3xl rounded-r-none mr-1 max-sm:w-full py-[17px] pl-6"
                                />
                                <JncCustomSelect
                                    value={formData.cryptoId}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            cryptoId: e.target.value,
                                        })
                                    }
                                    options={balancesCrypto}
                                    className="max-sm:w-[200px] !rounded-l-none"
                                    inputProps={{
                                        className: '!py-[15px]',
                                    }}
                                />
                            </div>
                        </div>
                        <div className="mb-[30px] flex justify-between items-center w-full max-sm:flex-wrap">
                            <label className="text-base font-normal text-start">
                                Receive:
                            </label>
                            <div className="max-sm:pt-3 flex items-center max-sm:w-full">
                                <input
                                    type="text"
                                    name='fiatAmount'
                                    data-e2e="receive-field"
                                    onChange={handleAmountChange}
                                    value={formData.fiatAmount}
                                    className="bg-input rounded-l-3xl rounded-r-none mr-1 max-sm:w-full py-[17px] pl-6"
                                />
                                <JncCustomSelect
                                    value={formData.fiatCurrency}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            fiatCurrency: e.target.value,
                                        })
                                    }
                                    options={balancesCurrencies}
                                    className="max-sm:w-[200px] !rounded-l-none"
                                    inputProps={{
                                        className: '!py-[15px]',
                                    }}
                                />
                            </div>
                        </div>
                        <div className="mb-[30px] flex justify-between items-center">
                            <label className="text-base font-normal text-start">
                                Rate:
                            </label>
                            <div className="text-base font-normal">
                                <span>1 {formData.cryptoId} = {toFixedTrunc(1 / exchangeRate, 2)} {formData.fiatCurrency}</span>
                            </div>
                        </div>
                        <div className="mb-[30px] flex justify-between items-center">
                            <label className="text-base font-normal text-start">
                                Fee:
                            </label>
                            <span className="text-base font-normal">
                                {feePercentage}%
                            </span>
                        </div>
                        <div className=" flex justify-between items-center">
                            <label className="text-base font-normal text-start">
                                Total:
                            </label>
                            <span>{getCurrencySymbol(formData.fiatCurrency)} {formData.fiatAmount || 0} ({formData.fiatCurrency})</span>
                        </div>
                        {amountError && (
                            <div
                                className="jncAlert alert alert-danger"
                                role="alert"
                            >
                                {amountError}
                            </div>
                        )}
                    </> :
                    <SellConfirm formData={formData} exchangeRate={exchangeRate} />}
            </>
        )
    }

    const handleConfirmationModal = () => {
        if (formData.fiatAmount && formData.cryptoAmount && !amountError) {
            setIsConfirmModal(true)
        }
    }

    const dialogActionsComponent = () => {
        return (
            <div className="w-100">
                {
                    !isConfirmModal ?
                        <div className="jncModal__btns justify-end">
                            <JncButton
                                isFullOnMobile={true}
                                text="Continue"
                                dataE2e="continue-btn"
                                onClickCall={handleConfirmationModal}
                            />
                        </div> :
                        <div className="jncModal__btns grid-on-mobile justify-end no-border">
                            <JncButton
                                isFullOnMobile={true}
                                text="Cancel"
                                onClickCall={() => handleClose()}
                            />
                            <JncButton
                                isFullOnMobile={true}
                                text="Sell Now"
                                onClickCall={() => createCryptoTransaction()}
                            />
                        </div>
                }
            </div>
        )
    }
    return (
        <JunoClientActionModal
            onClose={handleClose}
            titles={{ start: 'Sell Crypto', confirm: 'Sell Crypto' }}
            backTo={{ account: () => setIsConfirmModal(false) }}
            states={{
                start: !isConfirmModal,
                confirm: isConfirmModal,
            }}
            open={open}
            dialogContent={dialogContentComponent()}
            dialogActions={dialogActionsComponent()}
        ></JunoClientActionModal>
    )
}

export default BalanceCryptoSell
