import React from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import Icon from '../../icons/Icon'
import _ from 'lodash'
import { DialogTitle } from '@mui/material'

export default function JncDialogTitleTransaction({ transaction, onClose }) {
    return (
        <div>
            {transaction && (
                <DialogTitle id="scroll-dialog-title" className="">
                    <div className="jncTransactionModal">
                        <div className="jncTransactionModal__header">
                            <Icon id={_.camelCase(transaction.type)} />
                            <div className="jncTransactionModal__title">
                                {transaction.type}
                            </div>
                        </div>
                        <button
                            onClick={onClose}
                            type="button"
                            className="jncModal__close"
                        >
                            <ClearIcon />
                        </button>
                    </div>
                </DialogTitle>
            )}
        </div>
    )
}
