import React from 'react'
import { useSelector } from 'react-redux'
import { Typeahead } from 'react-bootstrap-typeahead'

export default function JncCurrenciesTypeahead({
    onChange,
    selected,
    hasError,
    label,
    fromCurrency,
}) {
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)
    const activeCurrencyPage = useSelector((state) => state.juno.activeCurrencyPage)
    const balancesFiatData = useSelector((state) => state.juno.balancesCurrencies)
    const balancesCryptoData = useSelector((state) => state.juno.balancesCrypto)
    const filteredBalancesData = activeCurrencyPage === 'fiat' ? balancesFiatData : balancesCryptoData;
    const currencyList = filteredBalancesData.filter((x) => x.currencyType === activeCurrencyPage)

    const currencyListOptions = currencyList.map((x) =>
        fromCurrency
            ? fromCurrency !== x.currencyShortName && x.currencyShortName
            : x.currencyShortName,
    )
    function getCurrencyIcon(name) {
        const currency = currencyList.find((x) => x.currencyShortName === name)
        return currency ? currency.currencyIcon : null
    }

    return (
        <div className="jncModal__field jncModal__field-currency mb">
            <p className="jncModal__label">
                {label || 'Currency'} <span className="required">*</span>
            </p>
            <div
                className={`typeahead-custom ${selected.length > 0 ? 'has-value' : ''} ${hasError ? 'has-error' : ''}`}
            >
                {selected.length > 0 && (
                    <img
                        className="typeahead-custom-img"
                        src={`${imageBaseUrl}${getCurrencyIcon(selected[0])}`}
                    />
                )}
                <Typeahead
                    id="basic-typeahead-multiple-currency"
                    labelKey={(option) => `${option}`}
                    onChange={onChange}
                    options={currencyListOptions}
                    placeholder="Select currency"
                    selected={selected}
                    clearButton={true}
                    filterBy={(option, props) => {
                        return option != ''
                    }}
                    renderMenuItemChildren={(option) => (
                        <div className="jncTypeahead-item">
                            {getCurrencyIcon(option) && (
                                <img
                                    src={`${imageBaseUrl}${getCurrencyIcon(option)}`}
                                />
                            )}
                            <p data-e2e={option}>{option}</p>
                        </div>
                    )}
                />
            </div>
            {hasError && (
                <div className="jncAlert alert alert-danger" role="alert">
                    * Currency field is required
                </div>
            )}
        </div>
    )
}
