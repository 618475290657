import React, { useState } from 'react'
import JunoClientActionModal from '../../../../components/table/JunoClientActionModal'
import JncButton from '../../../../components/global/JncButton'
import { useSelector } from 'react-redux'
import { JncCustomSelect } from '../../../../components/global/JncCustomSelect'
import Icon from '../../../../icons/Icon'
import { apiService } from '../../../../../../common/apiCallService'
import { removeCommas, toFixedTrunc, useJunoCommonFunctions, validateAmount } from '../../../../../helpers'
import ExchangeConfirmation from './confirmModal/ExchangeConfirmation'

export const BalanceCrytoExchange = (props) => {
    const { onClose, open } = props
    const { balancesCrypto } = useSelector(
        (state) => state.juno
    )
    const { getCryptoBalances } = useJunoCommonFunctions()
    const apiUrl = useSelector((state) => state.config.api_url)
    const clientData = useSelector((state) => state.juno?.clientData)
    const [amountError, setAmountError] = useState('')
    const [exchangeRate, setExchangeRate] = useState(1)
    const [isConfirmModal, setIsConfirmModal] = useState(false)

    const [formData, setFormData] = useState({
        fromCryptoAmount: null,
        toCryptoAmount: null,
        fromCryptoId: 'BTC',
        toCryptoId: 'ETH',
    })

    const handleClose = () => {
        onClose()
        setIsConfirmModal(false)
        setFormData({
            fromCryptoAmount: null,
            toCryptoAmount: null,
            fromCryptoId: 'BTC',
            toCryptoId: 'ETH',
        })
        setAmountError('')
    }

    const feePercentage = 0.2

    const handleAmountChange = (e) => {
        if (exchangeRate) {
            let fromCryptoAmount, toCryptoAmount, amountError
            const name = e.target.name
            const value = e.target.value

            if (name === 'fromCryptoAmount') {
                const fixedRate = 1 / exchangeRate
                toCryptoAmount = fixedRate && removeCommas(value) * fixedRate
                toCryptoAmount = toFixedTrunc(toCryptoAmount, 2)
                fromCryptoAmount = value
                amountError = validateAmount(
                    removeCommas(fromCryptoAmount),
                    0,
                    formData.fromCryptoAmount,
                    balancesCrypto
                )
            } else if (name === 'toCryptoAmount') {
                fromCryptoAmount = exchangeRate && removeCommas(value) * exchangeRate
                fromCryptoAmount = toFixedTrunc(fromCryptoAmount, 2)
                toCryptoAmount = value
                amountError = validateAmount(
                    value,
                    0,
                    formData.fromCryptoAmount,
                    balancesCrypto
                )
            }
            setFormData({ ...formData, fromCryptoAmount, toCryptoAmount })
            setAmountError(amountError)
        }
    }

    const createCryptoTransaction = () => {
        const blockchain = balancesCrypto.find(crypto => crypto.currencyShortName === formData.toCryptoId)?.blockchain
        const payload = {
            currencyType: 'crypto',
            type: 'Exchange',
            clientId: clientData?.clientId,
            transactionEmail: localStorage.getItem('user_name'),
            transactionDetails: {
                ...formData,
                blockchain,
                fxrate: 0.8386378,
                // "transactionFee": "0.83"
            },
        }
        apiService(
            (window.location.hostname === 'localhost' ? apiUrl : '') +
            '/restapi/create-client-transaction-crypto',
            payload,
            async (data) => {
                getCryptoBalances()
                handleClose()
            },
            (err) => {
                console.log(err)
            },
        )
    }

    const handleConfirmationModal = () => {
        if (formData.fromCryptoAmount && formData.toCryptoAmount && !amountError) {
            setIsConfirmModal(true)
        }
    }

    const dialogContentComponent = () => {
        return (
            <>
                {!isConfirmModal ?
                    <>
                        <div className="mb-[20px] flex justify-between items-center max-sm:flex-wrap">
                            <label className="text-base font-normal text-start">
                                From:
                            </label>
                            <div className="max-sm:pt-3 flex items-center max-sm:w-full">
                                <input
                                    type="text"
                                    name='fromCryptoAmount'
                                    data-e2e="from-field"
                                    onChange={handleAmountChange}
                                    value={formData.fromCryptoAmount}
                                    className="bg-input rounded-l-3xl rounded-r-none mr-1 max-sm:w-full py-[17px] pl-6"
                                />
                                <JncCustomSelect
                                    value={formData.fromCryptoId}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            fromCryptoId: e.target.value,
                                        })
                                    }
                                    options={balancesCrypto}
                                    className="max-sm:w-[200px] !rounded-l-none"
                                    inputProps={{
                                        className: '!py-[15px]',
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex items-center w-full mb-[20px]">
                            <hr className="flex-grow h-[1px]" />
                            <span className="w-10 h-10 flex items-center justify-center border rounded-full">
                                <Icon id="exchangeIcon" />
                            </span>
                        </div>
                        <div className="mb-[30px] flex justify-between items-center w-full max-sm:flex-wrap">
                            <label className="text-base font-normal text-start">
                                To:
                            </label>
                            <div className="max-sm:pt-3 flex items-center max-sm:w-full">
                                <input
                                    name='toCryptoAmount'
                                    type="text"
                                    onChange={handleAmountChange}
                                    value={formData.toCryptoAmount}
                                    className="bg-input rounded-l-3xl rounded-r-none mr-1 max-sm:w-full py-[17px] pl-6"
                                />
                                <JncCustomSelect
                                    value={formData.toCryptoId}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            toCryptoId: e.target.value,
                                        })
                                    }
                                    options={balancesCrypto}
                                    className="max-sm:w-[200px] !rounded-l-none"
                                    inputProps={{
                                        className: '!py-[15px]',
                                    }}
                                />
                            </div>
                        </div>
                        <div className="mb-[30px] flex justify-between items-center">
                            <label className="text-base font-normal text-start">
                                Rate:
                            </label>
                            <div className="text-base font-normal">
                                <span>1 USDT = 1 USD</span>
                            </div>
                        </div>
                        <div className="mb-[30px] flex justify-between items-center">
                            <label className="text-base font-normal text-start">
                                Fee:
                            </label>
                            <span className="text-base font-normal">
                                {feePercentage}%
                            </span>
                        </div>
                        <div className=" flex justify-between items-center">
                            <label className="text-base font-normal text-start">
                                Total:
                            </label>
                            <span>₹ 202 (USDT)</span>
                        </div>
                        {amountError && (
                            <div
                                className="jncAlert alert alert-danger"
                                role="alert"
                            >
                                {amountError}
                            </div>
                        )}
                    </> : <ExchangeConfirmation formData={formData} />
                }
            </>
        )
    }

    const dialogActionsComponent = () => {
        return (
            <div className="w-100">
                {
                    !isConfirmModal ?
                        <div className="jncModal__btns justify-end">
                            <JncButton
                                isFullOnMobile={true}
                                text="Continue"
                                dataE2e="continue-btn"
                                onClickCall={handleConfirmationModal}
                            />
                        </div> :
                        <div className="jncModal__btns grid-on-mobile justify-end no-border">
                            <JncButton
                                isFullOnMobile={true}
                                text="Cancel"
                                onClickCall={() => handleClose()}
                            />
                            <JncButton
                                isFullOnMobile={true}
                                text="Exchange Now"
                                onClickCall={() => createCryptoTransaction()}
                            />
                        </div>
                }
            </div>
        )
    }
    return (
        <JunoClientActionModal
            onClose={handleClose}
            titles={{ start: 'Exchange', confirm: 'Exchange Confirmation' }}
            backTo={{ account: () => setIsConfirmModal(false) }}
            states={{
                start: true,
                confirm: false,
            }}
            open={open}
            dialogContent={dialogContentComponent()}
            dialogActions={dialogActionsComponent()}
        ></JunoClientActionModal>
    )
}
