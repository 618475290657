import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import AccountCard from '../global/AccountCard'
import JncSectionTitle from '../global/JncSectionTitle'
import { clearAllValuesInObj, useJunoCommonFunctions } from '../../../helpers'
import AccountAddress from '../global/AccountAddress'
import JunoClientActionModal from '../table/JunoClientActionModal'
import FieldsForEdit from '../../tables/balances/actions/fiat/FieldsForEdit'
import { setSuccessMessage } from '../../../../Redux/actions/junoAction'
import JncCurrenciesTypeahead from '../global/JncCurrenciesTypeahead'
import JncButton from '../global/JncButton'
import JncActionDelete from '../modals/JncActionDelete'
import Icon from '../../icons/Icon'
import BalanceFiatWithdraw from '../../tables/balances/actions/fiat/BalanceFiatWithdraw'
import BalanceFiatPaymentOut from '../../tables/balances/actions/fiat/BalanceFiatPaymentOut'
import JncEmptyData from '../table/JncEmptyData'
import JncPageTitle from '../global/JncPageTitle'

export default function JncAccounts({ isDashboardPage }) {
    const { getSavedAccounts, saveAccountApi, updateAccountApi } =
        useJunoCommonFunctions()
    const savedAccounts = useSelector((state) => state.juno.savedAccounts)
    const [accountModal, setAccountModal] = useState(false)
    const [accountDataModal, setAccountDataModal] = useState('')
    const [isEditAccount, setIsEditAccount] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isDelete, setIsDelete] = useState(false)
    const [isFailed, setIsFailed] = useState(false)
    const [failedErr, setFailedErr] = useState('')
    const [isNewAccountComponent, setIsNewAccountComponent] = useState(false)
    const dispatch = useDispatch()
    const [modalAction, setModalAction] = useState('')
    const [activeData, setActiveData] = useState({})
    const clientData = useSelector((state) => state.juno.clientData)
    const readOnly =
        clientData && clientData.readOnly ? clientData.readOnly : false
    const [errorInputNames, setErrorsInputNames] = useState([])

    function openAccountModal(data) {
        setAccountDataModal(data)
        setAccountModal(true)
        setIsNewAccountComponent(false)
    }

    function hideIsEditAccount() {
        setIsEditAccount(false)
        setFormData(clearAllValuesInObj(formData))
        setErrorsInputNames([])
    }

    useEffect(() => {
        getSavedAccounts()
    }, [])

    const [formData, setFormData] = useState({
        beneficiaryName: '',
        beneficiaryAddress: '',
        bankName: '',
        bankAddress: '',
        beneficiaryAccountNumber: '',
        sortCode: '',
        swift: '',
        iban: '',
        additionalBankDetails: '',
        amount: '',
        reference: '',
        accountNickName: '',
        saveBankAccount: false,
        currency: [],
    })

    function onSelectCurrency(val) {
        setFormData((x) => {
            return {
                ...x,
                currency: val,
            }
        })
        if (val.length > 0) {
            setErrorsInputNames((prev) => prev.filter((x) => x !== 'currency'))
        }
    }

    function onChangeInput(e) {
        const name = e.target.name
        let value
        if (e.target.type === 'checkbox') value = e.target.checked
        else value = e.target.value
        setFormData((x) => {
            return {
                ...x,
                [name]: value,
            }
        })
        if (
            name == 'accountNickName' &&
            value &&
            errorInputNames.includes('accountNickName')
        ) {
            setErrorsInputNames((prev) =>
                prev.filter((x) => x !== 'accountNickName'),
            )
        }
    }

    function onCloseHandler() {
        if (!isLoading) {
            setAccountModal(false)
            setTimeout(() => {
                setAccountDataModal('')
                setIsDelete(false)
                resetUseStates()
            }, 300)
        }
    }

    function reset() {
        setIsLoading(false)
        resetUseStates()
    }

    function resetUseStates() {
        setIsEditAccount(false)
        setIsFailed(false)
        setFormData(clearAllValuesInObj(formData))
        setFailedErr('')
        setErrorsInputNames([])
    }

    function onSuccessDelete() {
        dispatch(setSuccessMessage('Bank account deleted successfully!'))
        getSavedAccounts()
        setFormData(clearAllValuesInObj(formData))
        setIsEditAccount(false)
        setIsDelete(false)
        setAccountDataModal('')
        setAccountModal(false)
    }

    const handleIsEditAccount = (id) => {
        setIsNewAccountComponent(false)
        const account = savedAccounts.find((x) => x._id == id)
        setFormData((x) => {
            return {
                ...account,
                currency:
                    typeof accountDataModal.currency === 'string'
                        ? [accountDataModal.currency]
                        : accountDataModal.currency,
            }
        })
        setIsEditAccount(true)
    }

    function addNewAccount() {
        setIsNewAccountComponent(true)
        setAccountModal(true)
    }

    function validateFields() {
        let updatedErrors = []
        setErrorsInputNames((prev) => {
            if (!formData.accountNickName) {
                updatedErrors = [...updatedErrors, 'accountNickName']
            }
            if (!formData.currency[0]) {
                updatedErrors = [...updatedErrors, 'currency']
            }
            return updatedErrors
        })
        return updatedErrors
    }

    function onSaveAccountSuccess() {
        getSavedAccounts()
        setFormData({
            beneficiaryName: '',
            beneficiaryAddress: '',
            bankName: '',
            bankAddress: '',
            beneficiaryAccountNumber: '',
            sortCode: '',
            swift: '',
            iban: '',
            additionalBankDetails: '',
            amount: '',
            reference: '',
            accountNickName: '',
            saveBankAccount: false,
            currency: [],
        })
        setAccountModal(false)
        setIsLoading(false)
        setIsFailed(false)
        dispatch(
            setSuccessMessage('New bank account has been added successfully'),
        )
        setErrorsInputNames([])
    }
    function onSaveAccountError() {
        setIsLoading(false)
        setIsFailed(true)
    }
    function onUpdateAccountSuccess() {
        getSavedAccounts()
        hideIsEditAccount()
        setAccountModal(false)
        setIsLoading(false)
        dispatch(setSuccessMessage('Bank account updated successfully'))
    }
    function onUpdateAccountError() {
        setIsLoading(false)
    }

    function saveAccount() {
        validateFields()
        if (!formData.accountNickName || !formData.currency[0]) return

        setIsLoading(true)
        if (!isEditAccount) {
            saveAccountApi(
                formData,
                onSaveAccountSuccess,
                onSaveAccountError,
                setFailedErr,
            )
        } else {
            updateAccountApi(
                formData,
                onUpdateAccountSuccess,
                onUpdateAccountError,
            )
        }
    }

    const setIsDeleteTrue = () => setIsDelete(true)

    function DialogContentComponent() {
        return (
            <div>
                <div className="jncAccountModal">
                    {accountDataModal && !isEditAccount && (
                        <AccountAddress
                            data={accountDataModal}
                            setIsAccountEdit={handleIsEditAccount}
                            savedAccounts={savedAccounts}
                        />
                    )}
                    {accountDataModal && isEditAccount && (
                        <div>
                            <JncCurrenciesTypeahead
                                selected={formData.currency}
                                onChange={onSelectCurrency}
                                hasError={errorInputNames.includes('currency')}
                            />
                            <FieldsForEdit
                                isEditAccount={isEditAccount}
                                formData={formData}
                                onChangeInput={onChangeInput}
                                errorInputNames={errorInputNames}
                            />
                        </div>
                    )}
                </div>

                {isNewAccountComponent && !accountDataModal && (
                    <div>
                        <JncCurrenciesTypeahead
                            selected={formData.currency}
                            onChange={onSelectCurrency}
                            hasError={errorInputNames.includes('currency')}
                        />
                        <FieldsForEdit
                            errorInputNames={errorInputNames}
                            isEditAccount={true}
                            formData={formData}
                            onChangeInput={onChangeInput}
                        />
                    </div>
                )}
            </div>
        )
    }

    function DialogActionsComponent() {
        return (
            <div className="w-100">
                {!accountDataModal && isNewAccountComponent && (
                    <div className="jncModal__btns justify-end grid-on-mobile">
                        <JncButton
                            text="Close"
                            disabled={isLoading}
                            onClickCall={onCloseHandler}
                            isOutlinedStyle={true}
                        />
                        <JncButton
                            text="Continue"
                            loading={isLoading}
                            onClickCall={saveAccount}
                            dataE2e="submit"
                        />
                    </div>
                )}
                {accountDataModal && !isEditAccount && (
                    <div>
                        <div className="jncModal__actions deleteTwoBtns min-516">
                            <div className="deleteTwoBtns-left">
                                <JncActionDelete
                                    onClickCall={setIsDeleteTrue}
                                    readOnly={readOnly}
                                />
                                <button
                                    data-e2e="delete-icon"
                                    onClick={() =>
                                        handleIsEditAccount(
                                            accountDataModal._id,
                                        )
                                    }
                                    disabled={readOnly}
                                    type="button"
                                    className={`jncActionBtn jncHint jncBalanceCard__actions-item ${readOnly && 'jnc-btn-disable'}`}
                                >
                                    <svg
                                        width="45"
                                        height="44"
                                        viewBox="0 0 45 44"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M13.5 31V26.75L26.7 13.575C26.9 13.3917 27.121 13.25 27.363 13.15C27.605 13.05 27.859 13 28.125 13C28.3917 13 28.65 13.05 28.9 13.15C29.15 13.25 29.3667 13.4 29.55 13.6L30.925 15C31.125 15.1833 31.271 15.4 31.363 15.65C31.455 15.9 31.5007 16.15 31.5 16.4C31.5 16.6667 31.4543 16.921 31.363 17.163C31.2717 17.405 31.1257 17.6257 30.925 17.825L17.75 31H13.5ZM28.1 17.8L29.5 16.4L28.1 15L26.7 16.4L28.1 17.8Z"
                                            fill="black"
                                        />
                                    </svg>
                                </button>
                            </div>
                            <div className="deleteTwoBtns-right">
                                <button
                                    className={`jncActionBtn jncHint ${readOnly ? 'jnc-btn-disable' : ''}`}
                                    disabled={readOnly}
                                    onClick={() => {
                                        setModalAction('paymentOut')
                                    }}
                                >
                                    <span>Third-party payment</span>
                                    <Icon id="paymentOut" />
                                </button>
                                <button
                                    className={`jncActionBtn jncHint ${readOnly ? 'jnc-btn-disable' : ''}`}
                                    disabled={readOnly}
                                    onClick={() => {
                                        setModalAction('withdraw')
                                    }}
                                >
                                    <span>Withdrawal</span>
                                    <Icon id="withdrawal" />
                                </button>
                            </div>
                        </div>
                        <div className="max-515 jncAccountModal__btns">
                            <button
                                onClick={() => setIsDelete(true)}
                                type="button"
                                disabled={readOnly}
                                className={`${readOnly ? 'jnc-btn-disable' : ''}`}
                            >
                                <svg
                                    width="45"
                                    height="44"
                                    viewBox="0 0 45 44"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        x="0.5"
                                        width="44"
                                        height="44"
                                        rx="22"
                                        fill="#F2F4F7"
                                    />
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M18.606 12.553C18.689 12.3869 18.8166 12.2472 18.9745 12.1496C19.1324 12.0519 19.3143 12.0001 19.5 12H25.5C25.6857 12.0001 25.8676 12.0519 26.0255 12.1496C26.1834 12.2472 26.311 12.3869 26.394 12.553L28.118 16H30.5C30.7652 16 31.0196 16.1054 31.2071 16.2929C31.3946 16.4804 31.5 16.7348 31.5 17C31.5 17.2652 31.3946 17.5196 31.2071 17.7071C31.0196 17.8946 30.7652 18 30.5 18H29.5V29C29.5 29.7956 29.1839 30.5587 28.6213 31.1213C28.0587 31.6839 27.2956 32 26.5 32H18.5C17.7044 32 16.9413 31.6839 16.3787 31.1213C15.8161 30.5587 15.5 29.7956 15.5 29V18H14.5C14.2348 18 13.9804 17.8946 13.7929 17.7071C13.6054 17.5196 13.5 17.2652 13.5 17C13.5 16.7348 13.6054 16.4804 13.7929 16.2929C13.9804 16.1054 14.2348 16 14.5 16H16.882L18.606 12.553ZM24.882 14L25.882 16H19.118L20.118 14H24.882ZM21.5 21C21.5 20.7348 21.3946 20.4804 21.2071 20.2929C21.0196 20.1054 20.7652 20 20.5 20C20.2348 20 19.9804 20.1054 19.7929 20.2929C19.6054 20.4804 19.5 20.7348 19.5 21V27C19.5 27.2652 19.6054 27.5196 19.7929 27.7071C19.9804 27.8946 20.2348 28 20.5 28C20.7652 28 21.0196 27.8946 21.2071 27.7071C21.3946 27.5196 21.5 27.2652 21.5 27V21ZM25.5 21C25.5 20.7348 25.3946 20.4804 25.2071 20.2929C25.0196 20.1054 24.7652 20 24.5 20C24.2348 20 23.9804 20.1054 23.7929 20.2929C23.6054 20.4804 23.5 20.7348 23.5 21V27C23.5 27.2652 23.6054 27.5196 23.7929 27.7071C23.9804 27.8946 24.2348 28 24.5 28C24.7652 28 25.0196 27.8946 25.2071 27.7071C25.3946 27.5196 25.5 27.2652 25.5 27V21Z"
                                        fill="black"
                                    />
                                </svg>
                                <p>Delete</p>
                            </button>
                            <button
                                onClick={() =>
                                    handleIsEditAccount(accountDataModal._id)
                                }
                                type="button"
                                disabled={readOnly}
                                className={`${readOnly ? 'jnc-btn-disable' : ''}`}
                            >
                                <svg
                                    width="45"
                                    height="44"
                                    viewBox="0 0 45 44"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        x="0.5"
                                        width="44"
                                        height="44"
                                        rx="22"
                                        fill="#F2F4F7"
                                    />
                                    <path
                                        d="M13.5 31V26.75L26.7 13.575C26.9 13.3917 27.121 13.25 27.363 13.15C27.605 13.05 27.859 13 28.125 13C28.3917 13 28.65 13.05 28.9 13.15C29.15 13.25 29.3667 13.4 29.55 13.6L30.925 15C31.125 15.1833 31.271 15.4 31.363 15.65C31.455 15.9 31.5007 16.15 31.5 16.4C31.5 16.6667 31.4543 16.921 31.363 17.163C31.2717 17.405 31.1257 17.6257 30.925 17.825L17.75 31H13.5ZM28.1 17.8L29.5 16.4L28.1 15L26.7 16.4L28.1 17.8Z"
                                        fill="black"
                                    />
                                </svg>
                                <p>Edit</p>
                            </button>
                            <button
                                onClick={() => setModalAction('paymentOut')}
                                type="button"
                                disabled={readOnly}
                                className={`${readOnly ? 'jnc-btn-disable' : ''}`}
                            >
                                <svg
                                    width="45"
                                    height="44"
                                    viewBox="0 0 45 44"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        x="0.875"
                                        width="44"
                                        height="44"
                                        rx="22"
                                        fill="#F2F4F7"
                                    />
                                    <path
                                        d="M25.875 17H19.875M25.875 21H19.875M25.875 25H21.875M15.875 13H29.875V31L28.843 30.116C28.4805 29.8053 28.0189 29.6346 27.5415 29.6346C27.0641 29.6346 26.6025 29.8053 26.24 30.116L25.208 31L24.177 30.116C23.8145 29.8051 23.3526 29.6342 22.875 29.6342C22.3974 29.6342 21.9355 29.8051 21.573 30.116L20.542 31L19.51 30.116C19.1475 29.8053 18.6859 29.6346 18.2085 29.6346C17.7311 29.6346 17.2695 29.8053 16.907 30.116L15.875 31V13Z"
                                        stroke="black"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                <p>Third-party payment</p>
                            </button>
                            <button
                                onClick={() => setModalAction('withdraw')}
                                type="button"
                                disabled={readOnly}
                                className={`${readOnly ? 'jnc-btn-disable' : ''}`}
                            >
                                <svg
                                    width="45"
                                    height="44"
                                    viewBox="0 0 45 44"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        x="0.625"
                                        width="44"
                                        height="44"
                                        rx="22"
                                        fill="#F2F4F7"
                                    />
                                    <path
                                        d="M22.625 14L22.625 30"
                                        stroke="black"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M29.625 21L22.625 14L15.625 21"
                                        stroke="black"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                <p>Withdrawal</p>
                            </button>
                        </div>
                    </div>
                )}
                {accountDataModal && isEditAccount && (
                    <div className="jncModal__btns justify-end grid-on-mobile">
                        <JncButton
                            disabled={isLoading}
                            onClickCall={hideIsEditAccount}
                            text="Cancel"
                            isOutlinedStyle={true}
                        />
                        <JncButton
                            loading={isLoading}
                            onClickCall={saveAccount}
                            text="Save"
                        />
                    </div>
                )}
            </div>
        )
    }
    return (
        <div>
            {!isDashboardPage && (
                <JncPageTitle title="Saved Bank Accounts" headerBtn="Add new" headerBtnAction={addNewAccount} />
            )}

            <section
                className={`jncSection mb jncAccounts ${
                    isDashboardPage ? 'is-dashboard' : ''
                }`}
            >
                {isDashboardPage && (
                    <JncSectionTitle
                        title="Saved Accounts"
                        seeAllLink="/client/accounts"
                    />
                )}
                {savedAccounts && savedAccounts.length < 1 && (
                    <div className="max-991">
                        <JncEmptyData text="You don't have any saved bank accounts" />
                    </div>
                )}
                <div className="jncCardsGrid__overflow">
                    <div
                        className={`jncCardsGrid ${
                            !isDashboardPage && !savedAccounts.length
                                ? 'no-grid'
                                : ''
                        }`}
                    >
                        <button
                            onClick={addNewAccount}
                            disabled={readOnly}
                            className={`jncAddItem min-992 ${
                                isDashboardPage ? 'is-dashboard' : ''
                            } ${savedAccounts.length ? 'no-mt' : ''}`}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="48"
                                height="48"
                                viewBox="0 0 48 48"
                                fill="none"
                            >
                                <path
                                    d="M24 4C12.954 4 4 12.954 4 24C4 35.046 12.954 44 24 44C35.046 44 44 35.046 44 24C44 12.954 35.046 4 24 4ZM34 26H26V34H22V26H14V22H22V14H26V22H34V26Z"
                                    fill="#344054"
                                />
                            </svg>
                            <p data-e2e="add-bank-account-btn">
                                Add bank account
                            </p>
                        </button>
                        {savedAccounts.map((x) => (
                            <AccountCard
                                openAccountModal={openAccountModal}
                                data={x}
                                key={x._id}
                            />
                        ))}
                    </div>
                </div>
            </section>

            <JunoClientActionModal
                onClose={onCloseHandler}
                open={accountModal}
                isLoading={isLoading}
                title={isNewAccountComponent ? 'Add new account' : ''}
                loadingText={''}
                customDialogTitle={'accounts'}
                isFailed={isFailed}
                reset={reset}
                failedErr={failedErr}
                //--- for accounts
                formData={accountDataModal}
                isDelete={isDelete}
                setIsDelete={setIsDelete}
                onSuccessDelete={onSuccessDelete}
                isEditAccount={isEditAccount}
                hideIsEditAccount={hideIsEditAccount}
                dialogContent={DialogContentComponent()}
                dialogActions={DialogActionsComponent()}
            ></JunoClientActionModal>

            <BalanceFiatWithdraw
                open={modalAction === 'withdraw'}
                onClose={() => setModalAction('')}
                row={activeData}
                selectCurrency={true}
                accountDetails={accountDataModal}
            />
            <BalanceFiatPaymentOut
                open={modalAction === 'paymentOut'}
                onClose={() => setModalAction('')}
                row={activeData}
                selectCurrency={true}
                accountDetails={accountDataModal}
            />
        </div>
    )
}
