export const ValidStatus = [
    'PrivateClient:TransactionCompleted',
    'PrivateClient:RequestForApproval',
    'PrivateClient:Rejected',
    'PrivateClient:ErrorEncountered',
]

export const TransactionTypes = [
    'Deposit',
    'FX',
    'Payment Out',
    'Transfer',
    'Withdrawal',
]

export const ErrorTransactions = [
    'PrivateClient:Rejected',
    'PrivateClient:ErrorEncountered',
]

export const COLORS = ["#3CCB7F", "#A48AFB", "#FDB022", "#22CCEE", "#E478FA", "#D0D5DD"];

export const cryptoCurrencyIcon = {
    BTC: "/common/crytoicons/btc.png",
    USDT: "/cryptonpay/usdt.png",
    LTC: "/common/crytoicons/ltc.png",
    ETH: "/common/crytoicons/eth1.png"
}