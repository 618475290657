import React from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { useSelector } from 'react-redux'
import Icon from '../../icons/Icon'
import { toFixedTrunc, getCurrencySymbol } from '../../../helpers'
import { useJunoCommonFunctions } from '../../../helpers'
import { DialogContent, Slide } from '@mui/material'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function SidebarCurrencyModal({
    onClose,
    open,
    data,
    setModalAction,
}) {
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)
    const clientData = useSelector((state) => state.juno.clientData)
    const readOnly =
        clientData && clientData.readOnly ? clientData.readOnly : false
    const { useWindowWidth } = useJunoCommonFunctions()
    const windowWidth = useWindowWidth()

    return (
        <Dialog
            className="jncModal"
            onClose={onClose}
            open={open}
            TransitionComponent={windowWidth < 768 ? Transition : undefined}
            classes={{ container: 'jncModal-bottom', root: 'jncModal-root' }}
        >
            <DialogTitle className="jncModal__header">
                <div className="jncSidebar__currHeader">
                    <img
                        src={`${imageBaseUrl}${data && data.currencyIcon}`}
                        alt=""
                    />
                    <div>
                        <p className="jncSidebar__currHeader-balance" data-e2e="balance" data-e2e-amount={`${toFixedTrunc(data && data.balanceAmount, 2)}`}>
                            {getCurrencySymbol(data && data.currencyShortName)}{' '}
                            {toFixedTrunc(data && data.balanceAmount, 2)}
                        </p>
                        <p className="jncSidebar__currHeader-percent">
                            {toFixedTrunc(data && data.holdingPercentage, 2)}%
                            holding of total balance
                        </p>
                    </div>
                </div>
                <button
                    onClick={onClose}
                    type="button"
                    className="jncModal__close"
                    data-e2e="close-icon"
                >
                    <ClearIcon />
                </button>
            </DialogTitle>
            <DialogContent dividers>
                <div className="jncModal__body">
                    <div className="jncSidebar__actions">
                        <button
                            onClick={() => {
                                setModalAction('deposit')
                            }}
                            disabled={readOnly}
                            className={`${readOnly && 'jnc-btn-disable'} jncSidebar__actions-btn`}
                        >
                            <div className="jncActions__icon">
                                <Icon id="deposit" />
                            </div>
                            <p className="jncSidebar__actions-title">Deposit</p>
                        </button>
                        <button
                            onClick={() => {
                                setModalAction('withdraw')
                            }}
                            disabled={readOnly}
                            className={`${readOnly && 'jnc-btn-disable'} jncSidebar__actions-btn`}
                        >
                            <div className="jncActions__icon">
                                <Icon id="withdrawal" />
                            </div>
                            <p className="jncSidebar__actions-title">
                                Withdrawal
                            </p>
                        </button>
                        <button
                            onClick={() => {
                                setModalAction('paymentOut')
                            }}
                            disabled={readOnly}
                            className={`${readOnly && 'jnc-btn-disable'} jncSidebar__actions-btn`}
                        >
                            <div className="jncActions__icon">
                                <Icon id="paymentOut" />
                            </div>
                            <p className="jncSidebar__actions-title">
                                Third-party payment
                            </p>
                        </button>
                        <button
                            onClick={() => {
                                setModalAction('transfer')
                            }}
                            disabled={readOnly}
                            className={`${readOnly && 'jnc-btn-disable'} jncSidebar__actions-btn`}
                        >
                            <div className="jncActions__icon">
                                <Icon id="transfer" />
                            </div>
                            <p className="jncSidebar__actions-title">
                                Transfer
                            </p>
                        </button>
                        <button
                            onClick={() => {
                                setModalAction('fx')
                            }}
                            disabled={readOnly}
                            className={`${readOnly && 'jnc-btn-disable'} jncSidebar__actions-btn last-child`}
                        >
                            <div className="jncActions__icon">
                                <Icon id="fx" />
                            </div>
                            <p className="jncSidebar__actions-title">FX</p>
                        </button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}
